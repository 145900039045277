import { Component, inject, Input, OnInit } from '@angular/core'
import { BiometryErrorType } from '@aparajita/capacitor-biometric-auth'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { BaseModal } from '@app/pages/base.modal'
import { MarkdownPipe } from '@app/pipes/markdown.pipe'
import { StripTagsPipe } from '@app/pipes/strip-tags.pipe'
import { TrPipe } from '@app/pipes/tr.pipe'
import { BiometricAuthService, biometryType } from '@app/srv/biometricAuth.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { SessionService } from '@app/srv/session.service'
import { StoreService } from '@app/srv/store.service'
import {
  IonButton,
  IonContent,
  IonFooter,
  IonImg,
  IonLabel,
  IonTitle,
} from '@ionic/angular/standalone'
import { BiometricAuthPermissionRevokedModal } from '../biometric-auth-permission-revoked/biometric-auth-permission-revoked.modal'

enum BioAuthBlockState {
  AuthNeeded = 'AuthNeeded',
  Failed = 'Failed',
}

@Component({
  imports: [
    MarkdownPipe,
    StripTagsPipe,
    TrPipe,
    IonContent,
    IonTitle,
    IonImg,
    IonFooter,
    IonButton,
    IonLabel,
  ],
  selector: 'app-biometric-auth-block-modal',
  templateUrl: './biometric-auth-block.modal.html',
  styleUrls: ['./biometric-auth-block.modal.scss'],
})
export class BiometricAuthBlockModal extends BaseModal implements OnInit {
  public className = 'BiometricAuthBlockModal'

  @Input()
  public coldStart = false

  public biometryType = biometryType
  public BioAuthBlockState = BioAuthBlockState
  public authState: BioAuthBlockState = BioAuthBlockState.AuthNeeded
  public biometryLocked = false

  private biometricAuthService = inject(BiometricAuthService)
  private popupController = inject(PopupController)
  private sessionService = inject(SessionService)
  private storeService = inject(StoreService)

  public async ngOnInit(): Promise<void> {
    this.biometricAuthService.onResume$.subscribe(async () => {
      this.authState = BioAuthBlockState.AuthNeeded
      await this.startBiometricAuthentication()
    })
    await this.startBiometricAuthentication()
  }

  public async startBiometricAuthentication(): Promise<void> {
    const { isAvailable, code } = await this.biometricAuthService.checkAvailability()

    if (code === BiometryErrorType.biometryLockout) {
      // Biometry is locked because there were too many failed attempts.
      // https://developer.apple.com/documentation/localauthentication/laerror/2867597-biometrylockout
      // Do not show the permission revoked modal, because user has to authenticate with passcode to unlock it.
      // There isn't seemed to be a way to provide passcode authentication directly in this library.
      this.biometryLocked = true
      this.authState = BioAuthBlockState.Failed
      return
    }

    if (!isAvailable) {
      const activePopup = this.popupController.getActivePopupById(
        'modal-biometric-auth-permission-revoked',
      )
      if (activePopup) return

      const modal = await this.popupController.presentModal(
        {
          component: BiometricAuthPermissionRevokedModal,
        },
        'modal-biometric-auth-permission-revoked',
        Priority.IMMEDIATE,
      )

      const { data } = await modal.onDidDismiss()
      if (!data) this.authState = BioAuthBlockState.Failed
    } else {
      const { success, errorCode } = await this.biometricAuthService.authenticate()
      if (success) return await this.unblockScreen()
      this.authState = BioAuthBlockState.Failed

      if (errorCode === BiometryErrorType.userCancel) {
        // Check availability again to see if the biometry is locked.
        const { code } = await this.biometricAuthService.checkAvailability()
        if (code === BiometryErrorType.biometryLockout) this.biometryLocked = true
      }
    }
  }

  public tryAgain(): void {
    this.biometricAuthService.onResume$.next()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async logout(): Promise<void> {
    await this.sessionService.logout()
  }

  private async unblockScreen(): Promise<void> {
    if (this.coldStart) this.storeService.dispatch('setLastActiveNow')
    await this.dismissModal(true)
  }
}
