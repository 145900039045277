import { Component, Input, ViewChild } from '@angular/core'
import { SafeHtml } from '@angular/platform-browser'
import { BaseModal } from '@app/pages/base.modal'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone'
import { ICON } from '@src/app/cnst/icons.cnst'
import { ScrollFooterComponent } from '../../cmp/scroll-footer/scroll-footer.component'
import { MarkdownPipe } from '../../pipes/markdown.pipe'
import { SafePipe } from '../../pipes/safe.pipe'
import { StripTagsPipe } from '../../pipes/strip-tags.pipe'
import { TrPipe } from '../../pipes/tr.pipe'

@Component({
  selector: 'page-info-modal',
  templateUrl: './info.modal.html',
  styleUrls: ['./info.modal.scss'],
  providers: [],
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    ScrollFooterComponent,
    IonLabel,
    MarkdownPipe,
    SafePipe,
    StripTagsPipe,
    TrPipe,
  ],
})
export class InfoModal extends BaseModal {
  className = 'InfoModal'

  @Input()
  public title?: string

  @Input()
  public body?: string

  @Input()
  public html?: SafeHtml

  @Input()
  public ctaTitle?: string

  @Input()
  public isLogoDisplayed?: boolean

  @Input()
  public infoBoxBody?: string

  @ViewChild(IonContent, { static: true })
  content!: IonContent

  public ICON = ICON
}
