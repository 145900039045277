import { Injectable } from '@angular/core'
import { isAB291Test } from '@src/ab291/ab291'
import { PageGuard } from '@src/app/guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class WelcomeScreenAB281TestGuard extends PageGuard {
  className = 'WelcomeScreenAB281Test2Guard'

  get requirements(): boolean[] {
    const requirements = [
      !this.hasAccountId(),
      this._isAB291Test(),
      !this.hasViewedAB291WelcomeScreen(),
    ]

    return requirements
  }

  _isAB291Test(): boolean {
    return isAB291Test()
  }
}
