import { Component } from '@angular/core'
import { IllustrationComponent } from '@app/cmp/illustration/illustration.component'
import { BaseModal } from '@app/pages/base.modal'
import { TrPipe } from '@app/pipes/tr.pipe'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonToolbar,
} from '@ionic/angular/standalone'

@Component({
  selector: 'app-fresh-battery-modal',
  templateUrl: './fresh-battery.modal.html',
  styleUrls: ['./fresh-battery.modal.scss'],
  imports: [
    TrPipe,
    IllustrationComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonFooter,
    IonLabel,
  ],
})
export class FreshBatteryModal extends BaseModal {
  public className = 'FreshBatteryModal'
}
