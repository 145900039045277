import { BluetoothClientMock } from '@app/mocks/BleClient.mock'
import { DatePickerDate, DatePickerTime } from '@app/model/datePicker.model'
import {
  HKCategoryWriteInput,
  HKDataIdentifier,
  HKDataIdentifierInput,
  HKQuantityWriteInput,
} from '@app/model/healthKit.model'
import {
  AdditionalInformation,
  ApplePayProcessorType,
  BraintreePaymentMethodType,
} from '@app/model/payment.model'
import { AppearanceSettings } from '@app/srv/appearance.service'
import { AppTrackingTransparencyStatus } from '@app/srv/appTrackingTransparency.service'
import { WatchInitInput, WatchInput, WatchStatus } from '@app/srv/companionWatch.cnst'
import type { Orientation } from '@app/srv/orientation.model'
import { T3BatteryLevel } from '@app/srv/t3.cnst'
import { URLOpenListener } from '@capacitor/app'
import { PluginListenerHandle, registerPlugin } from '@capacitor/core'
import { BleClient } from '@capacitor-community/bluetooth-le'
import { NumberOfSeconds, StringMap, UnixTimestamp } from '@naturalcycles/js-lib'
import {
  HKCategorySample,
  HKQuantitySample,
  NCHKSample,
  WatchModel,
  WidgetDataForToday,
  WidgetDay,
} from '@naturalcycles/shared'
import { IAPTransactionRaw } from '@src/app/srv/iap.service'
import { env } from '@src/environments/environment'
import { T3PluginMock } from '../app/plugins/T3Mock'

interface BadgePlugin {
  checkPermission: () => Promise<{ state: number }>
  set: (opt: { value: number }) => void
  clear: () => void
}

interface AndroidDatePickerPlugin {
  showTimePicker: (opt: DatePickerTime) => Promise<DatePickerTime | undefined>

  showDatePicker: (opt: {
    minDate?: number
    maxDate?: number
    /**
     * 0-based!!!
     */
    month: number
    year: number
    description?: string
    title: string
    day: any
    spinner?: boolean
  }) => Promise<DatePickerDate>
}

interface AndroidMinimizePlugin {
  minimizeApp: () => Promise<void>
}

interface NativeSettingsPlugin {
  addListener: (event: string, cb: () => any) => Promise<void>

  open: () => Promise<void>

  openNotificationSettings: () => Promise<void>

  enableBluetooth: () => Promise<void>
}

interface AndroidNotchPlugin {
  getStatusBarHeight: () => Promise<{
    statusBarHeight?: number
  }>
}

interface AndroidFileSystemCheckPlugin {
  checkImagesFolder: () => Promise<{
    imageFound?: boolean
  }>
}

interface NCSharePlugin {
  share: (opt: { text?: string; url?: string; image?: string; title?: string }) => Promise<void>
}

interface NCScreenshotPlugin {
  available: () => Promise<{ available: boolean }>
  addListener: (event: 'screenshot', cb: () => any) => Promise<void>
  removeAllListeners: () => Promise<void>
}

interface NavigationBarPlugin {
  updateNavigationBarColor: () => Promise<void>
}

interface SignInWithApplePlugin {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Authorize: () => Promise<AppleAuthUser>
}
export interface NCExternalPurchasePlugin {
  presentNoticeSheet: () => Promise<{ token?: string; canceled?: boolean; canPresent?: boolean }>
}

export interface NCAppearancePlugin {
  getSystemPreference: () => Promise<{ appearance: AppearanceSettings }>
}

export interface AppleAuthUser {
  user: string
  email: string | null
  givenName: string | null
  familyName: string | null
  identityToken: string
  authorizationCode: string
}

export interface GoogleAuthUser {
  id: string
  email: string
  name: string
  familyName: string
  givenName: string
  imageUrl?: string
  authentication: Authentication
}

interface Authentication {
  accessToken?: string
  idToken: string
}

interface GoogleAuthPlugin {
  signIn: () => Promise<GoogleAuthUser>
  refresh: () => Promise<Authentication>
  signOut: () => Promise<void>
}

interface DynamicLinksPlugin {
  addListener: (
    eventName: 'dynamicLinkOpen',
    listenerFunc: URLOpenListener,
  ) => Promise<PluginListenerHandle>
}

interface WidgetInitInput {
  outdated: WidgetDataForToday
}

interface WidgetUpdateInput {
  today: WidgetDataForToday
  predictions: WidgetDay[]
}

interface NCWidgetPlugin {
  initWidget: (args: { input: WidgetInitInput }) => void
  update: (args: { input: WidgetUpdateInput }) => void
}

interface ScreenOrientationPlugin {
  lock: (args: { orientation: Orientation }) => Promise<void>
  unlock: () => Promise<void>
  addListener: (event: string, cb: (data: { orientation: Orientation }) => any) => Promise<void>
}

interface NCHapticsPlugin {
  impact: () => Promise<void>
}

interface DropinUIOptions {
  token: string
  threeDSecure?: DropinUI3DSecureOptions
  cartSummary?: ApplePayCartItemsOptions
  googlePayOptions?: GooglePayOptions
}

interface DropinUI3DSecureOptions {
  amount: string
  challengeRequested?: boolean
  email?: string
  mobilePhoneNumber?: string
  additionalInformation?: AdditionalInformation
}

interface ApplePayCartItemsOptions {
  totalPrice: string
  isProd: boolean // require billing address check
  items?: CartItemsSummary[]
}

interface GooglePayOptions {
  totalPrice: string // [0-9]+(\.[0-9][0-9])? (e.g. "10.45")
  currencyCode: string // ISO 4217
}

interface CartItemsSummary {
  [key: string]: string
}

interface DropinUIResult {
  cancelled: boolean
  deviceData: string
  nonce: string
  /**
   * Specifies the payment method type. e.g. Apple Pay, Google Pay, Credit Card or PayPal.
   */
  type: BraintreePaymentMethodType
  /**
   * Specifies the underlying payment processor, e.g. Visa, MasterCard, Amex
   * Note: Only available when using Apple Pay
   */
  source?: ApplePayProcessorType
}

interface DropinUIPlugin {
  renderUI: (opt: DropinUIOptions) => Promise<DropinUIResult>
}

interface NCLHPlugin {
  detectLHTest: (args: {
    imageData: string
    imageSource: 'CAMERA' | 'GALLERY'
    attempt?: number
  }) => Promise<{ isLHTest: boolean }>
  /**
   * Must be called after detectLHTest returns {isLHTest: true}, rejects otherwise
   * */
  classifyLHTest: () => Promise<{
    isPositive: boolean
    mlOutput: number
    img: string
    userImg: string
  }>
}

interface NCInAppReviewPlugin {
  requestReview: () => Promise<void>
}

export interface FOTAUpdateInput {
  filename: string // filename without extension
}

export interface T3DeviceStatus {
  timestamp: UnixTimestamp
  state: number // eCommands
  stateString: string // keyof DeviceState but lowercase
  deviceMode: number // eDeviceMode
  deviceModeString: string
  batteryLevel: number
  batteryState: T3BatteryLevel
  batteryStateString: string // keyof BatteryLevel but lowercase
  unSyncedLogs: boolean
  PSUEnable: boolean
  measureInProgress: boolean
}

export interface T3Plugin {
  abortFOTA: () => Promise<void>
  performFOTA: (args: FOTAUpdateInput) => Promise<void>
  connect: (args: { toDevice: string }) => Promise<void>
  initialize: () => Promise<void>
  scan: () => Promise<void>
  stopScan: () => Promise<void>
  getDevices: () => Promise<{ devices: any[] }>
  getDeviceStatus: () => Promise<T3DeviceStatus>
  sendCommand: (args: { command: number; data: number[] }) => Promise<void>
  enableWakeLock: () => Promise<void>
  disableWakeLock: () => Promise<void>
  addListener: ((
    event: 'fotaDidProgress',
    cb: (data: { progress: number }) => void,
  ) => Promise<PluginListenerHandle>) &
    ((event: 'fotaDidSucceed' | 'fotaDidFail', cb: () => void) => Promise<PluginListenerHandle>) &
    ((
      event: 'log',
      cb: (data: { key?: string; msg: string }) => void,
    ) => Promise<PluginListenerHandle>)
}

interface NCHealthKitPlugin {
  isAvailable: () => Promise<{ isAvailable: boolean }>
  enableBackgroundDelivery: () => Promise<{ result: boolean }>
  hasCompatibleAppleWatch: (args: { models: StringMap<WatchModel> }) => Promise<{ result: boolean }>
  /**
   * Returned samples are sorted by endTimestamp in descending order
   */
  readDataForIdentifier: <T extends HKCategorySample | HKQuantitySample = NCHKSample>(args: {
    dataIdentifier: HKDataIdentifierInput
    startTimestamp?: UnixTimestamp // inclusive
    endTimestamp?: UnixTimestamp // inclusive
    limit?: number
    ascending?: boolean
  }) => Promise<{ data: T[] }>
  readAggregatedDataForIdentifier: <
    T extends HKCategorySample | HKQuantitySample = NCHKSample,
  >(args: {
    dataIdentifier: HKDataIdentifierInput
    postPeriod: NumberOfSeconds
    startTimestamp?: UnixTimestamp // inclusive
  }) => Promise<{ data: T[] }>
  writeDataForIdentifier: <T extends HKCategoryWriteInput | HKQuantityWriteInput>(args: {
    data: T
  }) => Promise<void>
  requestPermissions: (args: {
    readDataIdentifiers: HKDataIdentifierInput[]
    writeDataIdentifiers: HKDataIdentifierInput[]
  }) => Promise<{ result?: boolean }>
  canRequestPermissionForIdentifier: (args: {
    readDataIdentifiers: HKDataIdentifierInput[]
    writeDataIdentifiers: HKDataIdentifierInput[]
  }) => Promise<{ result: boolean }>
  checkPermissions: (args?: {
    dataIdentifiers: HKDataIdentifierInput[]
  }) => Promise<{ result: { [k in HKDataIdentifier]: boolean } }>
}

export interface RequestPermissionResponse {
  status: AppTrackingTransparencyStatus
  message?: string
  language?: string
}

interface NCAppTrackingTransparencyPlugin {
  getStatus: () => Promise<{ status: AppTrackingTransparencyStatus }>
  requestPermission: () => Promise<RequestPermissionResponse>
}

interface NCWatchPlugin {
  initWatch: (args: { input: WatchInitInput }) => Promise<void>
  updateWatch: (args: { input: WatchInput }) => Promise<void>
  addListener: (
    eventName: 'NCWatchStatus',
    cb: (event: WatchStatus) => void,
  ) => Promise<PluginListenerHandle>
  getSessionDetails: () => Promise<WatchStatus>
}

interface NCMoEngagePlugin {}

interface NCInAppPurchasePlugin {
  listenForTransactions: () => Promise<void>
  getTransactions: () => Promise<{ transactions: IAPTransactionRaw[] }>
  finishTransaction: (args: { productId: string }) => Promise<void>
  getProducts: (args: { productIds: string[] }) => Promise<any>
  subscribe: (args: { productId: string }) => Promise<IAPTransactionRaw>
  addListener: (
    eventName: 'transactionUpdate',
    cb: (transaction: IAPTransactionRaw) => void,
  ) => Promise<PluginListenerHandle>
}

interface NCSentryPlugin {
  mockError: () => Promise<void>
}

/* eslint-disable @typescript-eslint/naming-convention */
export const AndroidDatePicker = registerPlugin<AndroidDatePickerPlugin>('AndroidDatePicker')
export const AndroidMinimize = registerPlugin<AndroidMinimizePlugin>('AndroidMinimize')
export const AndroidNotch = registerPlugin<AndroidNotchPlugin>('AndroidNotch')
export const AndroidFileSystem =
  registerPlugin<AndroidFileSystemCheckPlugin>('AndroidFileSystemCheck')
export const Badge = registerPlugin<BadgePlugin>('Badge')
export const NCHaptics = registerPlugin<NCHapticsPlugin>('NCHaptics')
export const NativeSettings = registerPlugin<NativeSettingsPlugin>('NativeSettings')
export const NavigationBar = registerPlugin<NavigationBarPlugin>('NavigationBar')
export const NCShare = registerPlugin<NCSharePlugin>('NCShare')
export const NCWidget = registerPlugin<NCWidgetPlugin>('NCWidget')
export const NCScreenshot = registerPlugin<NCScreenshotPlugin>('NCScreenshot')
export const ScreenOrientation = registerPlugin<ScreenOrientationPlugin>('ScreenOrientation')
export const SignInWithApple = registerPlugin<SignInWithApplePlugin>('SignInWithApple')
export const NCExternalPurchase = registerPlugin<NCExternalPurchasePlugin>('NCExternalPurchase', {
  web: () =>
    import('../app/plugins/NCExternalPurchaseMock').then(m => new m.NCExternalPurchaseMock()),
})
export const GoogleAuth = registerPlugin<GoogleAuthPlugin>('GoogleAuth')
export const DynamicLinks = registerPlugin<DynamicLinksPlugin>('DynamicLinks')
export const DropinUI = registerPlugin<DropinUIPlugin>('DropinUI')
export const NCLH = registerPlugin<NCLHPlugin>('NCLH')
export const NCAppearance = registerPlugin<NCAppearancePlugin>('NCAppearance', {
  web: () => import('../app/plugins/NCAppearance').then(m => new m.NCAppearanceWeb()),
})
export const NCInAppReview = registerPlugin<NCInAppReviewPlugin>('NCInAppReview')
export const T3 = env.mockBluetooth ? (T3PluginMock as T3Plugin) : registerPlugin<T3Plugin>('T3')
export const NCHealthKit = registerPlugin<NCHealthKitPlugin>('NCHealthKit')
export const NCAppTrackingTransparency = registerPlugin<NCAppTrackingTransparencyPlugin>(
  'NCAppTrackingTransparency',
)
export const NCWatch = registerPlugin<NCWatchPlugin>('NCWatch')
export const NCMoEngage = registerPlugin<NCMoEngagePlugin>('NCMoEngage')
export const NCInAppPurchase = registerPlugin<NCInAppPurchasePlugin>('NCInAppPurchase')
export const NCSentry = registerPlugin<NCSentryPlugin>('NCSentry')

export const BluetoothClient = env.mockBluetooth ? BluetoothClientMock : BleClient
/* eslint-enable @typescript-eslint/naming-convention */
