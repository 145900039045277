import { BaseGuardService } from '@app/srv/base.guard.service'
import { RecentlyUsedHormones } from '@naturalcycles/shared'
import { measuringDeviceList } from '../util/product.util'
import { getState } from './store.service'

export class RouteGuardService extends BaseGuardService {
  public hasViewedAB291WelcomeScreen(): boolean {
    return !!getState().ui.hasViewedAB291WelcomeScreen
  }

  public hasSession(): boolean {
    return !!getState().sessionId
  }

  public hasAccountCompleteDate(): boolean {
    return !!getState().account.completeDate
  }

  public isInDemoMode(): boolean {
    return !!getState().account.demoMode
  }

  public hasUserFertilityTodayDate(): boolean {
    return !!getState().userFertility.todayDate
  }

  public cartTotalPriceNoAmount(): boolean {
    const { totalPrice } = getState().cart
    return !!totalPrice && !totalPrice.amount
  }

  public cartHasNothingLeftToPay(): boolean {
    const { cart } = getState()
    return !cart.totalPrice?.amount && !cart.totalPriceWithoutDiscount?.amount
  }

  public isHBCDemo(): boolean {
    const { account } = getState()
    return (
      !!account.demoMode &&
      account.onboardingData?.recentlyUsedHormones === RecentlyUsedHormones.YES_STILL
    )
  }

  public hasPartnerAccount(): boolean {
    return !!getState().partnerAccount
  }

  public hasAvailableDevices(): boolean {
    const availableDevices = getState().product.items.filter(item =>
      measuringDeviceList.includes(item.key),
    )
    return !!availableDevices.length
  }
}
