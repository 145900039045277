import { BackendResponseFM, RetailUpsellOfferFM, RetailUpsellOfferId } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

export interface RetailUpsellOfferState {
  dismissedIds: RetailUpsellOfferId[]
  offer: RetailUpsellOfferFM | null
}

const initialState: RetailUpsellOfferState = {
  dismissedIds: [],
  offer: null,
}

const actions: any = {
  dismissRetailUpsellOffer(
    state: RetailUpsellOfferState,
    action: { type: string; payload: RetailUpsellOfferId },
  ): RetailUpsellOfferState {
    return {
      ...state,
      dismissedIds: [...state.dismissedIds, action.payload],
    }
  },

  clearDismissedUpsellOffers(state: RetailUpsellOfferState): RetailUpsellOfferState {
    return {
      ...state,
      dismissedIds: [],
    }
  },

  onBackendResponse(
    state: RetailUpsellOfferState,
    action: { type: string; payload: BackendResponseFM },
  ): RetailUpsellOfferState {
    if (!action.payload.retailUpsellOffer) return state
    return {
      ...state,
      offer: action.payload.retailUpsellOffer.offer,
    }
  },
}

export const retailUpsellOfferReducer: Reducer<RetailUpsellOfferState> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
