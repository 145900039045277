import { inject, Injectable } from '@angular/core'
import { AdjustService } from '@app/analytics/adjust.service'
import {
  ADJUST_ONLY_EVENT,
  ADJUST_TOKENS,
  DONT_REPORT_PAGEVIEW,
  EVENT,
} from '@app/analytics/analytics.cnst'
import { ViewType } from '@app/analytics/analytics.model'
import { MixPanelService } from '@app/analytics/mixpanel.service'
import { appVer } from '@app/cnst'
import { isAndroidApp, isIOSApp, isNativeApp, isWebApp } from '@app/cnst/userDevice.cnst'
import { sentryService } from '@app/srv/sentry.service'
import { getState, StoreService } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import { StringMap } from '@naturalcycles/js-lib'
import { HardwareId, partnerAnalyticsPrefix, Price } from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { distinctUntilChanged } from 'rxjs/operators'
import { MoeService } from './moe.service'

const VALID = 'ng-valid'
const INVALID = 'ng-invalid'

let analyticsService: AnalyticsService

/**
 * Can't be used for any personal (PII) data!
 *
 * @see {AnalyticsService.addAnalyticsProps}
 */
export function addAnalyticsProps(props: StringMap<any>): void {
  analyticsService.addAnalyticsProps(props)
  return
}

export function getAnalyticsProps(): StringMap<any> {
  return analyticsService.analyticsProps
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private storeService = inject(StoreService)
  private mixpanelService = inject(MixPanelService)
  private adjustService = inject(AdjustService)
  private moeService = inject(MoeService)

  /**
   * Can't be used for storing any personal (PII) data!
   * These props are sent to Mixpanel which is allowed to access only safe or sensitive data
   */
  private _analyticsProps: StringMap = {}

  constructor() {
    analyticsService = this
  }

  async init(): Promise<void> {
    const viewType = this.getViewType()
    env.mixpanelServiceConf.viewType = viewType
    window.appVer = appVer // expose window.appVer (for analytics)

    await Promise.all([
      this.mixpanelService.init(),
      this.adjustService.init(),
      this.moeService.init(),
    ])

    void this.adjustService.addSessionCallbackParameter(
      'ncDistinctId',
      this.mixpanelService.distinctId,
    )

    if (isNativeApp) {
      this.storeService
        .select(s => s.account.externalPersonalId)
        .pipe(distinctUntilChanged())
        .subscribe(externalPersonalId => {
          if (externalPersonalId) {
            void this.moeService.setUniqueId(externalPersonalId)
          }
        })
    }

    this.storeService
      .select(s => s.account.externalAccountId)
      .pipe(distinctUntilChanged())
      .subscribe(externalAccountId => {
        if (externalAccountId) {
          sentryService.setUser(externalAccountId)
        }
      })

    this.storeService
      .select(s => s.account.hwId)
      .pipe(distinctUntilChanged())
      .subscribe(hwId => {
        sentryService.setTags({ hwId: HardwareId[hwId] })
      })
  }

  identify(): void {
    const { account } = getState()

    this.mixpanelService.identify(account.externalAccountId)
  }

  logEvent(event: string, props = {}): void {
    void this.mixpanelService.trackEvent(event, props)
  }

  trackEvent(event: EVENT, props = {}, price?: Partial<Price>): void {
    if (this.isPartner) {
      event = `${partnerAnalyticsPrefix} ${event}` as EVENT
    }

    logUtil.log('[analytics] event', event, JSON.stringify(props))

    if (ADJUST_TOKENS[event]) {
      void this.trackAdjust(ADJUST_TOKENS[event]!, price)
    }
    // Only Adjust
    if (ADJUST_ONLY_EVENT.includes(event)) return

    this.mixpanelService.trackEvent(event, { ...props })
  }

  trackView(page: string, _props = {}): void {
    if (DONT_REPORT_PAGEVIEW.includes(page)) return

    const props = {
      ..._props,
      ...this._analyticsProps,
    }

    // reset analytics props after we collected them
    this.resetAnalyticsProps()

    // await pDelay(1000) // perf delay
    logUtil.log('[analytics] page view', page, JSON.stringify(props))

    this.mixpanelService.trackView(page, { ...props }, this.isPartner)

    // TODO [2025-05-01] revisit me
    // It was decided to not track page views for now, but keeping the code in case we want to add it again
    // this.moeService.trackEvent(MoeEvent.pageViewApp, {
    //   generalAttributes: [{ name: 'pageName', value: page }],
    // }),
  }

  trackElement(el: HTMLElement, event: EVENT): void {
    if (event === EVENT.INPUT_VALIDATION) {
      this.trackValidity(el)
      return
    }
    const element = el.getAttribute('uid') || el.getAttribute('tmd')

    if (element) {
      this.trackEvent(event, { element })
    }
  }

  sendOfflineEvents(): void {
    const { ui, offlineEvents: events } = getState()
    if (!ui.online) return

    for (const mixpanelView of events.mixpanelViews!) {
      void this.mixpanelService.trackView(
        mixpanelView.page,
        mixpanelView.properties,
        this.isPartner,
      )
    }

    for (const mixpanelEvent of events.mixpanelEvents!) {
      this.mixpanelService.trackEvent(mixpanelEvent.name, mixpanelEvent.properties)
    }

    this.storeService.dispatch('clearOfflineEvents')
  }

  private trackAdjust(token: string, price?: Partial<Price>): void {
    const { externalPersonalId } = getState().account

    if (externalPersonalId) {
      this.adjustService.trackEvent(token, price?.amount, price?.currency, undefined, {
        ncAccountId: externalPersonalId,
      })
    }
  }

  private trackValidity(el: HTMLElement): void {
    const input = el.querySelector('input')
    const element =
      el.getAttribute('uid') ||
      el.getAttribute('tmd') ||
      (input && (input.getAttribute('uid') || input.getAttribute('tmd')))
    const valid = el.classList.contains(VALID) && el.dataset['lastLoggedClass'] !== VALID
    const invalid = el.classList.contains(INVALID) && el.dataset['lastLoggedClass'] !== INVALID

    if (!element) return

    if (valid || invalid) {
      el.dataset['lastLoggedClass'] = valid ? VALID : INVALID
      this.trackEvent(EVENT.INPUT_VALIDATION, { element, valid })
    }
  }

  getViewType(): ViewType {
    if (isWebApp) return 'WebApp'
    if (isIOSApp) return 'iOSApp'
    if (isAndroidApp) return 'AndroidApp'
    return 'App'
  }

  /**
   * Can't be used for any personal (PII) data!
   *
   * @see {AnalyticsService._analyticsProps}
   */
  public addAnalyticsProps(props: StringMap): void {
    this._analyticsProps = {
      ...this._analyticsProps,
      ...props,
    }
  }

  private resetAnalyticsProps(): void {
    this._analyticsProps = {}
  }

  get analyticsProps(): StringMap {
    return this._analyticsProps
  }

  get isPartner(): boolean {
    return !!getState().partnerAccount
  }
}
