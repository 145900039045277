import { Config } from '@lottiefiles/dotlottie-web'
import { _mapValues, StringMap } from '@naturalcycles/js-lib'

const cycleAnimationConfig = {
  backgroundColor: 'white',
}

const yearInReviewConfig = {
  loop: true,
}

export const ANIMATION: Readonly<StringMap<AnimationConfig>> = {
  'cycle-length': { ...cycleAnimationConfig },
  PERIOD: { ...cycleAnimationConfig },
  FOLLICULAR: { ...cycleAnimationConfig },
  OVULATION: { ...cycleAnimationConfig },
  OVULATION_WAITING: { ...cycleAnimationConfig },
  LUTEAL: { ...cycleAnimationConfig },
  'orange-purple-orbs': { ...yearInReviewConfig },
  'purple-blue-orbs': { ...yearInReviewConfig },
  'persona-transition': { ...yearInReviewConfig, mode: 'bounce' },
  'company-orbs': { ...yearInReviewConfig },
}

export type AnimationConfig = Omit<Config, 'canvas'>

export const DEFAULT_CONFIG: AnimationConfig = {
  autoplay: true,
  layout: {
    align: [0.5, 0.5],
    fit: 'contain',
  },
}

const ANIMATION_PATH = './assets/animations/'
_mapValues(ANIMATION, (key, value) => ({ ...value, src: `${ANIMATION_PATH}${key}.lottie` }), true)
