import { inject, Injectable } from '@angular/core'
import { nextIdle } from '@app/perf/idle.util'
import { LagMon } from '@app/perf/lagmon'
import { scenario01AddDataOpenClose } from '@app/perf/scenarios/scenario01AddDataOpenClose'
import { scenario02CalendarTabs } from '@app/perf/scenarios/scenario02CalendarTabs'
import { scenario03BottomTabs } from '@app/perf/scenarios/scenario03BottomTabs'
import { scenario04Graph } from '@app/perf/scenarios/scenario04Graph'
import { scenario05AddDataSave } from '@app/perf/scenarios/scenario05AddDataSave'
import { scenario06AutoAddDataOpen } from '@app/perf/scenarios/scenario06AutoAddDataOpen'
import { AdminService } from '@app/srv/admin.service'
import { api } from '@app/srv/api.service'
import { AppService } from '@app/srv/app.service'
import { di, runOutsideAngular } from '@app/srv/di.service'
import { firebaseService } from '@app/srv/firebase.service'
import { NavService } from '@app/srv/nav.service'
import { QAService } from '@app/srv/qa.service'
import { storageService } from '@app/srv/storage.service'
import { storage3Service } from '@app/srv/storage3/storage3.service'
import { Plugins } from '@capacitor/core'
import { GraphDataService } from '@src/app/pages/graph/srv/graphData.service'
import { env } from '../../environments/environment'
import { DeviceService } from './device.service'
import { EventService } from './event.service'
import { StoreService } from './store.service'

const EXPOSE_SERVICES: { [name: string]: any } = {
  qaService: QAService,
  appService: AppService,
  navService: NavService,
  storeService: StoreService,
  graphService: GraphDataService,
  deviceService: DeviceService,
  adminService: AdminService,
  eventService: EventService,
}

const EXPOSE_MORE: { [name: string]: any } = {
  storage3Service,
  firebaseService,
}

@Injectable({ providedIn: 'root' })
export class DebugService {
  private storeService = inject(StoreService)
  private qaService = inject(QAService)

  init(): void {
    const w: any = window

    // Expose Capacitor plugins
    Object.assign(w, {
      Plugins,
    })

    Object.keys(EXPOSE_SERVICES).forEach(serviceName => {
      w[serviceName] = di.get(EXPOSE_SERVICES[serviceName])
    })

    w.api = api

    Object.keys(EXPOSE_MORE).forEach(serviceName => {
      w[serviceName] = EXPOSE_MORE[serviceName]
    })

    w.state = () => this.storeService.getState()
    w.dispatch = (type: string, payload?: any) => this.storeService.dispatch(type, payload)

    w.reset = () => {
      this.storeService.dispatch('reset')
    }

    w.toggleAppearance = () => {
      const { appearance } = this.storeService.getState().userSettings

      this.storeService.dispatch('extendUserSettings', { appearance: appearance === 2 ? 1 : 2 })
    }

    w.getNavBarColor = () => {
      let rgb: number[] = []
      let backdropRgb: number[] = []
      let backdropOpacity = 0

      const backdrop = (
        document.querySelector('.modal--alert')?.shadowRoot ?? document.querySelector('ion-alert')
      )?.querySelector('ion-backdrop')
      if (backdrop) {
        const backdropBgColor = getComputedStyle(backdrop).backgroundColor.replace(
          /(?:rgba|rgb)/,
          '',
        )
        backdropRgb = backdropBgColor
          .substring(1, backdropBgColor.length - 1)
          .split(',')
          .map(c => Number(c.trim().replace(/\\D/g, '')))
        backdropOpacity = Number(getComputedStyle(backdrop).opacity)
      }

      const elements = document.elementsFromPoint(6, window.innerHeight - 1)
      for (let elem of elements) {
        if (!elem) continue
        if (elem.nodeName === 'ION-TAB-BUTTON') continue // skip tab buttons
        if (elem.nodeName === 'ION-CONTENT') {
          elem = elem.shadowRoot!.getElementById('background-content') ?? elem
        }
        // Trying to find the tab bar background to match its color with the android navigation bar
        if (elem.className === 'homePage__tabBarBackground') elem = elem.lastElementChild ?? elem
        const color = getComputedStyle(elem).backgroundColor.replace(/(?:rgba|rgb)/, '')
        rgb = color
          .substring(1, color.length - 1)
          .split(',')
          .map(c => Number(c.trim().replace(/\\D/g, '')))
        if (!(rgb[0] === 0 && rgb[1] === 0 && rgb[2] === 0)) break
      }

      return { rgb, backdropRgb, backdropOpacity }
    }

    w.statels = () => localStorage.getItem('state')
    w.statelspretty = () => JSON.stringify(JSON.parse(localStorage.getItem('state')!), undefined, 2)

    w.db = () => {
      const state = this.storeService.getState()
      if (!state.account.id) return 'no accountId'
      console.log(`${env.apiHost}/api/v3/qa/user/${state.account.id}?mode=dbm`)
    }

    w.logs = () => {
      const state = this.storeService.getState()
      if (!state.account.id) return 'no accountId'
      const project = env.prod ? 'ncbackend' : 'ncbackend-test'
      console.log(
        `https://console.cloud.google.com/logs/viewer?project=${project}&duration=P2D&key1=default&minLogLevel=0&expandAll=false&interval=P1D&resource=gae_app%2Fmodule_id%2Fdefault&filters=text:${state.account.id}`,
      )
      console.log(
        `https://console.cloud.google.com/logs/viewer?project=${project}&duration=P2D&key1=default&minLogLevel=0&expandAll=false&interval=P1D&resource=gae_app%2Fmodule_id%2Fmaster--medical-api&filters=text:${state.account.id}`,
      )
    }

    w.profile = () => w.ng.profiler.timeChangeDetection({ record: true })

    w.env = () => console.log(env)

    if (storageService.has('lagRadar')) {
      void this.qaService.toggleLagRadar()
    }

    w.toggleLagRadar = () => this.qaService.toggleLagRadar()

    runOutsideAngular(() => {
      w.lagMon = new LagMon()
    })

    w.scenarios = {
      scenario01AddDataOpenClose,
      scenario02CalendarTabs,
      scenario03BottomTabs,
      scenario04Graph,
      scenario05AddDataSave,
      scenario06AutoAddDataOpen,
    }

    window.nextIdle = nextIdle
  }
}

declare global {
  interface Window {
    nextIdle: typeof nextIdle
  }
}
