import { Injectable } from '@angular/core'
import { isAB291Test2 } from '@src/ab291/ab291'
import { PageGuard } from '@src/app/guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class WelcomeScreenAB281Test2Guard extends PageGuard {
  className = 'WelcomeScreenAB281Test2Guard'

  get requirements(): boolean[] {
    const requirements = [!this.hasAccountId(), this._isAB291Test2()]

    return requirements
  }

  _isAB291Test2(): boolean {
    return isAB291Test2()
  }
}
