import { Injectable } from '@angular/core'
import { isAB291Test, isAB291Test2 } from '@src/ab291/ab291'
import { PageGuard } from '../../guards/page.guard'

@Injectable({
  providedIn: 'root',
})
export class IntroPageGuard extends PageGuard {
  className = 'IntroPageGuard'

  get requirements(): boolean[] {
    const requirements = [!this.hasAccountId(), !this._isAB291Test(), !this._isAB291Test2()]

    return requirements
  }

  _isAB291Test(): boolean {
    return isAB291Test()
  }

  _isAB291Test2(): boolean {
    return isAB291Test2()
  }
}
