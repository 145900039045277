import { inject, Injectable } from '@angular/core'
import { postInitDone } from '@app/srv/milestones'
import { NCAppTrackingTransparency, RequestPermissionResponse } from '@src/typings/capacitor'
import { EVENT } from '../analytics/analytics.cnst'
import { AnalyticsService } from '../analytics/analytics.service'
import { AccountService } from './account.service'
import { sentryService } from './sentry.service'
import { getState } from './store.service'

export enum AppTrackingTransparencyStatus {
  /**
   * The value that returns when the app can’t determine the user’s authorization status for access to app-related data for tracking the user or the device.
   */
  NOT_DETERMINED = 0,
  /**
   * The value that returns if authorization to access app-related data for tracking the user or the device has a restricted status.
   */
  RESTRICTED = 1,
  /**
   * The value that returns if the user denies authorization to access app-related data for tracking the user or the device.
   */
  DENIED = 2,
  /**
   * The value that returns if the user authorizes access to app-related data for tracking the user or the device.
   */
  AUTHORIZED = 3,
}

const consentByStatus = {
  [AppTrackingTransparencyStatus.NOT_DETERMINED]: undefined,
  [AppTrackingTransparencyStatus.RESTRICTED]: false,
  [AppTrackingTransparencyStatus.DENIED]: false,
  [AppTrackingTransparencyStatus.AUTHORIZED]: true,
}

@Injectable({ providedIn: 'root' })
export class AppTrackingTransparencyService {
  private analyticsService = inject(AnalyticsService)
  private accountService = inject(AccountService)

  /**
   * It is enough to check if permission changed on cold start of the app,
   * If the user changed consent in Native settings, iOS will kill the app so next app open will be a cold start.
   */
  public async init(): Promise<void> {
    await this.checkIfPermissionChanged()
  }

  public async requestPermission(source: string): Promise<RequestPermissionResponse> {
    const { status, message, language } = await NCAppTrackingTransparency.requestPermission()

    if (!message) {
      sentryService.captureException('Missing NSUserTrackingUsageDescription in Info.plist')
    }

    void this.analyticsService.trackEvent(EVENT.REQUEST_ATT, {
      language,
      status: AppTrackingTransparencyStatus[status],
      source,
    })

    return {
      status,
      message,
      language,
    }
  }

  public async getStatus(): Promise<AppTrackingTransparencyStatus> {
    const { status } = await NCAppTrackingTransparency.getStatus()

    return status
  }

  public async saveMarketingSocialConsentIfChanged(
    marketingSocialConsent: boolean | undefined,
    status: AppTrackingTransparencyStatus,
  ): Promise<void> {
    // consent didnt change
    if (marketingSocialConsent === consentByStatus[status]) return

    await this.accountService.patch({
      marketingSocialConsent: status === AppTrackingTransparencyStatus.AUTHORIZED,
      marketingSocialConsentFromATT: true,
    })
  }

  private async checkIfPermissionChanged(): Promise<void> {
    const {
      account: { marketingSocialConsent, id },
      partnerAccount,
    } = getState()

    // logged out user or partner account, no need to check
    if (!id || !!partnerAccount) return

    const status = await this.getStatus()

    // we have not yet asked the user for permission, return
    if (status === AppTrackingTransparencyStatus.NOT_DETERMINED) return

    // consent changed, update it
    // awaiting postInit, otherwise there's a chance that
    // postInit would also save Account, which would lead to a race condition
    await postInitDone
    await this.saveMarketingSocialConsentIfChanged(marketingSocialConsent, status)
  }
}
