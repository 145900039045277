import { dispatch, getState } from '@app/srv/store.service'
import { _createPromiseDecorator, pDelay } from '@naturalcycles/js-lib'
import { di } from '../srv/di.service'
import { MarkdownService } from '../srv/markdown.service'
import { tr } from '../srv/translation.util'

export enum ErrorHandlerType {
  LOG = 'LOG',
  DIALOG = 'DIALOG',
}

export enum LoaderType {
  BLOCKING = 'BLOCKING',
  GHOST = 'GHOST',
  BUTTON = 'BUTTON',
}

export interface DecorateDecoratorParams {
  errorHandlerType?: ErrorHandlerType
  loaderType?: LoaderType
  messageKey?: string
}

const LOADER_MAP: Record<LoaderType, string> = {
  [LoaderType.BLOCKING]: 'setBlockingLoader',
  [LoaderType.GHOST]: 'setGhostLoader',
  [LoaderType.BUTTON]: 'setButtonLoader',
}

// It is done like this to avoid a circular dependency:
// QAService > decorators.ts > ErrorService > PopupController > QAService
// handleError function is re-assigned/initialized from "outside" (from bootstrap.service.ts)
type HandleErrorFn = (err: any, errorHandlerType?: ErrorHandlerType) => void
let handleError: HandleErrorFn = err => {
  // default implementation, but overridden later
  console.error(err)
}

export function initDecorators(handleErrorFn: HandleErrorFn): void {
  handleError = handleErrorFn
}

// function handleError(err: any, errorHandlerType?: ErrorHandlerType): void {
//   if (!errorHandlerType) return
//
//   if (errorHandlerType === ErrorHandlerType.DIALOG) {
//     void di.get(ErrorService).showErrorDialog(err)
//   } else {
//     di.get(ErrorService).logError(err)
//   }
//
//   // suppress error, don't propagate further, resolve(undefined)
// }

export const loader = (params: LoaderType): MethodDecorator =>
  _createPromiseDecorator(
    {
      decoratorName: 'loader',
      beforeFn: ({ decoratorParams: type }) => toggleLoader(type, true),
      finallyFn: ({ decoratorParams: type }) => toggleLoader(type, false),
    },
    params,
  )

export const errorHandler = (params: ErrorHandlerType): MethodDecorator =>
  _createPromiseDecorator(
    {
      decoratorName: 'errorHandler',
      catchFn: ({ err, decoratorParams: type }) => handleError(err, type),
    },
    params,
  )

/**
 * Decorator that combines Loader and ErrorHandler.
 * It's useful to not need to remember the order of these 2 decorators (Loader and ErrorHandler).
 * If order is used wrongly, BlockingLoader may still spin while ErrorHandler is showing the dialog.
 */
export const decorate = (params: DecorateDecoratorParams): MethodDecorator =>
  _createPromiseDecorator(
    {
      decoratorName: 'decorate',
      beforeFn: ({ decoratorParams: { loaderType, messageKey } }) =>
        toggleLoader(loaderType, true, messageKey),
      thenFn: async ({ decoratorParams: { loaderType }, res }) => {
        await toggleLoader(loaderType, false)
        return res
      },
      catchFn: ({ err, decoratorParams: { loaderType, errorHandlerType } }) => {
        void toggleLoader(loaderType, false)
        handleError(err, errorHandlerType || ErrorHandlerType.LOG)
      },
    },
    params,
  )

async function toggleLoader(
  loaderType?: LoaderType,
  show?: boolean,
  messageKey?: string,
): Promise<void> {
  if (!loaderType) return

  if (loaderType === LoaderType.BLOCKING) {
    const message = messageKey ? di.get(MarkdownService).parse(tr(messageKey)) : undefined
    return dispatch(LOADER_MAP[loaderType], show ? { show, message } : undefined)
  }

  dispatch(LOADER_MAP[loaderType], show)
  // Trigger a render event to draw the loading spinner immediately
  await pDelay()

  if (loaderType === LoaderType.BUTTON && show) {
    // safety check to remove button loader if its still loading after 10 seconds
    setTimeout(() => {
      if (getState().ui.buttonLoader) {
        dispatch('setButtonLoader', false)
      }
    }, 10000)
  }
}
