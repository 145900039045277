import { Experiment, getBucket } from '@naturalcycles/shared'
import { getState } from '@src/app/srv/store.service'

export const isAB291Test = (): boolean => {
  const assignment = getState().experiment.assignments[Experiment.WELCOME_SCREEN_APP]
  if (!assignment) return false
  return getBucket(assignment) === 'test'
}

export const isAB291Test2 = (): boolean => {
  const assignment = getState().experiment.assignments[Experiment.WELCOME_SCREEN_APP]
  if (!assignment) return false
  return getBucket(assignment) === 'test2'
}

export const isAb285TestorTest2Assignment = (): boolean => {
  return isAB291Test() || isAB291Test2()
}
