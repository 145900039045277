import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  output,
  ViewChild,
} from '@angular/core'
import { IonIcon, IonRadio, IonRadioGroup } from '@ionic/angular/standalone'
import { ICON } from '@src/app/cnst/icons.cnst'
import { MarkdownPipe } from '../../pipes/markdown.pipe'
import { TrPipe } from '../../pipes/tr.pipe'
import { InfoButtonComponent } from '../info-button/info-button.component'

export interface RadioGroupValue<T> {
  title: string
  subtitle?: string
  /**
   * Adding an icon will replace the radio icon
   */
  icon?: ICON
  infoButton?: string
  value: T
  disabled?: boolean
  uid: string
}

type RadioGroupType = string | boolean | number

@Component({
  selector: 'app-radio-group',
  templateUrl: 'radio-group.component.html',
  styleUrls: ['radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonRadioGroup, InfoButtonComponent, IonRadio, IonIcon, MarkdownPipe, TrPipe],
})
export class RadioGroupComponent {
  @ViewChild(IonRadioGroup)
  protected radioGroup!: IonRadioGroup

  @Input()
  protected title?: string

  @Input()
  public key?: string

  @Input()
  protected items?: RadioGroupValue<RadioGroupType>[]

  @Input()
  public value?: RadioGroupType

  @HostBinding('class.compact')
  @Input()
  protected compact?: boolean

  protected valueChange = output<any>()

  protected ionChange(event: CustomEvent): void {
    this.valueChange.emit(event.detail.value)
  }

  public setValue(value: RadioGroupType): void {
    this.radioGroup.value = value
  }

  protected trackByValue(_i: number, item: RadioGroupValue<RadioGroupType>): RadioGroupType {
    return item.value
  }
}
