<div class="multiEntries__content">
  @if (title) {
    <h2 class="ion-padding-horizontal">{{ title | tr }}</h2>
  }

  @if (body) {
    <p class="ion-padding-horizontal" [innerHTML]="body | tmd"></p>
  }

  @for (item of entryItems; track item.date) {
    <ion-radio-group
      [allowEmptySelection]="!item.hasSavedTemps"
      [value]="0"
      [name]="item.date"
      [class.multi]="item.entries.length !== 1"
    >
      @if (wearableMode) {
        @for (entry of item.entries; track $index; let i = $index) {
          <div class="entryItem__container">
            <div
              class="entryItem__row"
              [class.entryItem__row--timestamp]="entry.temperatureMeasuredTimestamp"
            >
              <ng-container *ngTemplateOutlet="entryTemperature; context: { entry }" />
              <ng-container *ngTemplateOutlet="entryTimestamp; context: { entry }" />

              <!-- Icon or Radio -->
              <div class="entryItem__icon">
                @if (entry.icon; as icon) {
                  <div
                    class="entryItem__statusIcon entryItem__wearable"
                    [class.entryItem__statusIcon--temperature]="icon.color === 'white'"
                  >
                    <ion-icon [color]="icon.color" [src]="icon.icon" />
                  </div>
                } @else {
                  <ion-radio
                    class="entryItem__wearableRadio"
                    slot="start"
                    mode="ios"
                    justify="start"
                    [value]="i"
                    [tabindex]="-1"
                  />
                }
              </div>

              @if (entry.today) {
                <div>{{ 'txt-today' | tr }}</div>
              }
            </div>
          </div>
        }
      } @else {
        @for (entry of item.entries; track $index; let i = $index) {
          <ion-radio
            class="entryItem--editable"
            slot="start"
            mode="ios"
            labelPlacement="end"
            justify="start"
            [value]="i"
          >
            <ng-container *ngTemplateOutlet="entryTemperature; context: { entry }" />
            <ng-container *ngTemplateOutlet="entryTimestamp; context: { entry }" />

            @if (!entry.temperatureMeasuredTimestamp && entry.temperatureUpdatedTimestamp) {
              <div class="entryItem__tag">
                <ion-label>{{ 'txt-manual' | tr }}</ion-label>
              </div>
            }
          </ion-radio>
        }
      }
    </ion-radio-group>
  }
</div>

<ion-footer class="footer--shadow">
  <ion-toolbar color="transparent">
    <ion-buttons slot="end">
      <ion-button uid="multiEntries__close" color="primary" (click)="dismiss()" tabindex="0">
        <ion-label>{{ dismissTitle | tr }}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<ng-template #entryTemperature let-entry="entry">
  @if (entry) {
    <div class="entryItem__temperature" [class.entryItem__temperature--deviating]="entry.deviating">
      {{ entry.temperature | temperature }}
    </div>
  }
</ng-template>

<ng-template #entryTimestamp let-entry="entry">
  @if (entry) {
    <div class="entryItem__date">
      @if (entry.temperatureMeasuredTimestamp; as timestamp) {
        {{ timestamp | nc_date: 'MEDIUM_WITH_TIME' }}
      } @else {
        {{ entry.date | nc_date: 'MEDIUM' }}
      }
    </div>
  }
</ng-template>
