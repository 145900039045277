import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { ClassName, EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { ROUTES } from '@app/cnst/nav.cnst'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { di } from '@app/srv/di.service'
import { bootstrapDone } from '@app/srv/milestones'
import { addNavParams } from '@app/srv/nav.service'
import { RouteGuardService } from '@app/srv/route-guard.service'
import { logUtil } from '@app/util/log.util'
import { getClassName } from '@app/util/string.util'
import { StringMap } from '@naturalcycles/js-lib'
import { isAB291Test, isAB291Test2 } from '@src/ab291/ab291'

interface RouteConditions {
  route: string
  conditions: StringMap<any>
  failedConditions: StringMap<any>
  matchAny?: boolean
}

export abstract class PageGuard extends RouteGuardService implements ClassName, CanActivate {
  abstract readonly className: string
  route!: ActivatedRouteSnapshot

  async canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    this.route = _route

    await bootstrapDone

    // can activate
    if (this.requirements.every(Boolean)) {
      return true
    }

    const page = getClassName(this)

    logUtil.log(`${page} blocked`)

    const { route, conditions, failedConditions } = this.getRoot()
    const { requirements } = this

    void di.get(AnalyticsService).trackEvent(EVENT.PAGE_GUARD_FAILED, {
      blockedPage: getClassName(this),
      route,
      conditions,
      failedConditions,
      requirements,
    })

    return di.get(Router).createUrlTree([route])
  }

  private getRoot(): RouteConditions {
    const hasSession = this.hasSession()
    const hasSubscription = this.hasSubscription()
    const hasAccountCompleteDate = this.hasAccountCompleteDate()
    const hasAvailableDevices = this.hasAvailableDevices()
    const hasValidAccountRequirements = !hasSubscription || hasAccountCompleteDate

    const failedConditions: StringMap<any> = {}

    const introConditions: RouteConditions = {
      route: ROUTES.IntroPage,
      conditions: {
        isNotAB291Test2: !isAB291Test2(),
        isNotAB291Test: !isAB291Test(),
        hasNoAccountId: !this.hasAccountId(),
        hasNoSession: !hasSession,
      },
      failedConditions,
    }

    const welcomeConditionsAB291Test2: RouteConditions = {
      route: ROUTES.WelcomePageAB291Test2,
      conditions: {
        isNotAB291Test2: isAB291Test2(),
        hasNoAccountId: !this.hasAccountId(),
        hasNoSession: !hasSession,
      },
      failedConditions,
    }

    const welcomeConditionsAB291Test: RouteConditions = {
      route: ROUTES.WelcomePageAB291Test,
      conditions: {
        hasNotViewedAB291WelcomeScreen: !this.hasViewedAB291WelcomeScreen(),
        isAB291Test: isAB291Test(),
        hasNoAccountId: !this.hasAccountId(),
        hasNoSession: !hasSession,
      },
      failedConditions,
    }

    const welcomeConditionsAB291TestAfterWelcome: RouteConditions = {
      route: ROUTES.QuizPrivacyPage,
      conditions: {
        hasNoAccountId: !this.hasAccountId(),
        hasNoSession: !hasSession,
      },
      failedConditions,
    }

    const consentConditions: RouteConditions = {
      route: ROUTES.ConsentPage,
      conditions: {
        requiresAppConsent: this.requiresAppConsent(),
      },
      failedConditions,
    }

    const homeConditions: RouteConditions = {
      route: ROUTES.HomePage,
      conditions: {
        hasAccountCompleteDate,
        hasUserFertilityTodayDate: this.hasUserFertilityTodayDate(),
      },
      failedConditions,
    }

    const partnerConditions: RouteConditions = {
      route: ROUTES.PartnerPage,
      conditions: {
        hasPartnerAccount: this.hasPartnerAccount(),
      },
      failedConditions,
    }

    const regDoneConditions: RouteConditions = {
      route: ROUTES.RegDonePage,
      conditions: {
        hasSubscription,
      },
      failedConditions,
      matchAny: true,
    }

    const measuringDeviceConditions: RouteConditions = {
      route: ROUTES.SignupMeasuringDevicePage,
      conditions: {
        hasSessionAndValidAccount: hasSession && hasValidAccountRequirements && hasAvailableDevices,
      },
      failedConditions,
      matchAny: true,
    }

    const plansPageConditions: RouteConditions = {
      route: ROUTES.PlansPage,
      conditions: {},
      failedConditions,
      matchAny: true,
    }

    const conditions = [
      introConditions,
      welcomeConditionsAB291Test2,
      welcomeConditionsAB291Test,
      welcomeConditionsAB291TestAfterWelcome,
      consentConditions,
      homeConditions,
      partnerConditions,
      regDoneConditions,
      plansPageConditions,
      measuringDeviceConditions,
    ]

    const response = conditions.find(item => {
      const response = this.checkRouteConditions({ ...item, failedConditions })
      if (response.match) return response.routeConditions
      Object.assign(failedConditions, response.routeConditions.failedConditions)
    })

    if (response) return response

    // if nothing works, go to error page
    addNavParams({ [NavigationParams.ERROR]: true })
    return {
      route: '/error',
      conditions: {},
      failedConditions,
    }
  }

  private checkRouteConditions(_route: RouteConditions): {
    match: boolean
    routeConditions: RouteConditions
  } {
    const routeConditions = { ..._route }
    const conditions = Object.values(routeConditions.conditions)

    // if we just want one truthy condition
    if (routeConditions.matchAny && conditions.includes(true)) {
      return { match: true, routeConditions }
    }

    // if all conditions needs to be truthy
    if (conditions.every(Boolean)) {
      return { match: true, routeConditions }
    }

    // if there was no match - add these conditions to failedConditions
    Object.assign(routeConditions.failedConditions, routeConditions.conditions)

    return { match: false, routeConditions }
  }

  abstract get requirements(): boolean[]
}
