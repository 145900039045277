import {
  BreakpointAction,
  ChoiceFunction,
  GoalBreakpointOption,
  MedicalConditionsBreakpointOption,
  QuizDataInput,
} from '@naturalcycles/shared'
import { addPageToFlowAfterCurrent, removePageFromFlow } from '@src/app/util/quiz.util'
import {
  Section,
  sectionCycleChangesEnd,
  sectionCycleChangesStart,
  sectionRoutine,
} from '../quiz.cnst'
import { QuizPage } from '../quiz-pages.cnst'

const sectionCycleEnd = [
  QuizPage.compareBirthControl,
  QuizPage.doctorInvolvement,
  QuizPage.partnerInvolvement,
  QuizPage.partnerInvolvementInfo,
  QuizPage.followPregnancyInfo,
]

const setCommonPart: ChoiceFunction<QuizPage, QuizDataInput> = (flow, data) => {
  const updated = [...flow]

  if (data?.fertilityGoal === GoalBreakpointOption.plan) {
    // Keep Section.cycle pages up until QuizPage.medicalConditions__Plan and remove the last part.
    const position = updated[Section.cycle]!.indexOf(QuizPage.medicalConditionsPlan)
    if (position !== -1) updated[Section.cycle]!.splice(position + 1, Infinity)
  }

  // Add the entire two last sections of the quiz
  updated[Section.cycleChanges] = [...sectionCycleChangesStart, ...sectionCycleChangesEnd]
  updated[Section.routine] = sectionRoutine

  return updated
}

const yesTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = (flow, data) => {
  let updated = [...flow]
  updated = setCommonPart(updated, data)

  if (data?.fertilityGoal === GoalBreakpointOption.plan) {
    // If user answer that she has a medical condition, just add one more info screen (QuizPage.medicalConditionsInfo)
    updated[Section.cycle] = [
      ...updated[Section.cycle]!,
      QuizPage.medicalConditionsInfo,
      ...sectionCycleEnd,
    ]
    return updated
  }

  // If user answer that she has a medical condition, just add one last info screen (QuizPage.medicalConditionsInfo)
  if (!updated[Section.cycle]?.includes(QuizPage.medicalConditionsInfo)) {
    // Remove compare birth control page in case it is already there to make sure it is added at the end
    removePageFromFlow(updated, Section.cycle, QuizPage.compareBirthControl)

    // Add medical conditions info page
    updated[Section.cycle] = [...updated[Section.cycle]!, QuizPage.medicalConditionsInfo]
  }

  return addPageToFlowAfterCurrent(
    updated,
    Section.cycle,
    QuizPage.medicalConditionsInfo,
    QuizPage.compareBirthControl,
  )
}

const noTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = (flow, data) => {
  let updated = [...flow]
  updated = setCommonPart(updated, data)

  if (data?.fertilityGoal === GoalBreakpointOption.plan) {
    updated[Section.cycle] = [...flow[Section.cycle]!, ...sectionCycleEnd]
    return updated
  }

  // Remove QuizPage.medicalConditionsInfo, it is not needed if the users answer that she does not have any medical condition
  const position = updated[Section.cycle]!.indexOf(QuizPage.medicalConditionsInfo)
  if (position !== -1) updated[Section.cycle]!.splice(position, 1)

  return addPageToFlowAfterCurrent(
    updated,
    Section.cycle,
    QuizPage.medicalConditionsPrevent,
    QuizPage.compareBirthControl,
  )
}
export const medicalConditions: BreakpointAction<
  MedicalConditionsBreakpointOption,
  QuizPage,
  QuizDataInput
> = {
  [MedicalConditionsBreakpointOption.endometriosis]: yesTransformFunction,
  [MedicalConditionsBreakpointOption.pcos]: yesTransformFunction,
  [MedicalConditionsBreakpointOption.thyroid]: yesTransformFunction,
  [MedicalConditionsBreakpointOption.skip]: noTransformFunction,
}
