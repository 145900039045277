import {
  Experiment,
  getBucket,
  GoalBreakpointOption,
  QuizDataInput,
  QuizEngine,
} from '@naturalcycles/shared'
import {
  Breakpoint,
  breakpointActions,
  commonQuizFlow,
  guardQuizData,
  Section,
} from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { BreakpointOption } from '@src/app/srv/quiz/quiz.service'
import { getState } from '@src/app/srv/store.service'
import { medicalConditionsAB285TestAndTest2 } from './medical-conditions-ab285'

export const isAB285Test = (): boolean => {
  const assignment = getState().experiment.assignments[Experiment.LEAD_CAPTURE_APP]
  if (!assignment) return false
  return getBucket(assignment) === 'test'
}

export const isAB285Test2 = (): boolean => {
  const assignment = getState().experiment.assignments[Experiment.LEAD_CAPTURE_APP]
  if (!assignment) return false
  return getBucket(assignment) === 'test2'
}

export const isAb285TestorTest2Assignment = (): boolean => {
  return isAB285Test() || isAB285Test2()
}

export const isEligibleForAb285 = (goal: GoalBreakpointOption | undefined): boolean => {
  return (
    !!goal &&
    goal !== GoalBreakpointOption.trackPregnancy &&
    goal !== GoalBreakpointOption.postPartum &&
    isAb285TestorTest2Assignment()
  )
}

const breakpointActionsAb285 = {
  ...breakpointActions,
  [Breakpoint.medicalConditions]: medicalConditionsAB285TestAndTest2,
}

export const quizEngineAB285TestAndTest2 = new QuizEngine<
  Breakpoint,
  BreakpointOption,
  QuizPage,
  Section,
  QuizDataInput
>({
  breakpointActions: breakpointActionsAb285,
  guardData: guardQuizData,
  initialFlow: commonQuizFlow,
})

export const imagePathAB285 = (): string | undefined => {
  if (isAB285Test()) {
    return '/assets/img/ab285/ab285test.png'
  }
  if (isAB285Test2()) {
    return 'https://assets.naturalcycles.com/images/onboarding-quiz/fit-result-info-prevent.png'
  }
}

export const titleAB285 = (goal: GoalBreakpointOption | undefined): string => {
  if (isAB285Test()) {
    if (goal === GoalBreakpointOption.plan) return 'We help Cyclers get pregnant — are you next?'
    if (goal === GoalBreakpointOption.prevent) return 'Join NC° community with over 3 million users'
    return '1 app, 4 modes, millions of unique journeys'
  }
  if (isAB285Test2()) {
    return 'Your personalized fertility journey is ready'
  }
  return ''
}
