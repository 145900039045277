import { ChangeDetectionStrategy, Component, inject, Input, output } from '@angular/core'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { NotesInputModal } from '@app/modals/notes-input/notes-input.modal'
import { addNavParams, getNavParams, removeNavParam } from '@app/srv/nav.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { IonButton, IonLabel } from '@ionic/angular/standalone'

@Component({
  selector: 'app-notes',
  templateUrl: 'notes.component.html',
  styleUrls: ['notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonButton, IonLabel],
})
export class NotesComponent {
  private popupController = inject(PopupController)

  protected showInput = false

  @Input()
  protected notes!: string

  @Input()
  protected placeholder?: string

  @Input()
  protected disabled?: boolean

  @Input()
  protected maxLines?: number

  @Input()
  protected readonly = false

  protected valueChange = output<string>()

  protected async openInput(): Promise<void> {
    if (this.disabled) return

    addNavParams({ [NavigationParams.NOTES]: this.notes })

    const modal = await this.popupController.presentModal(
      {
        component: NotesInputModal,
        cssClass: ['modal--transparent', 'modal--alert', 'modal--keyboard'],
        backdropDismiss: true,
        componentProps: {
          readonly: this.readonly,
        },
      },
      'modal-notesInput',
      Priority.IMMEDIATE,
    )
    await modal.onWillDismiss()

    this.notes = getNavParams()[NavigationParams.NOTES]

    removeNavParam(NavigationParams.NOTES)

    this.valueChange.emit(this.notes)
  }
}
