import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, ElementRef, input, viewChild } from '@angular/core'
import { DotLottie } from '@lottiefiles/dotlottie-web'
import { AnimationConfig, DEFAULT_CONFIG } from '@src/app/cnst/animations.cnst'

@Component({
  selector: 'app-animation',
  imports: [CommonModule],
  templateUrl: 'animation.component.html',
  styleUrls: ['animation.component.scss'],
})
export class AnimationComponent implements AfterViewInit {
  protected animation = input.required<AnimationConfig>()

  private animationContainer = viewChild.required<ElementRef>('animationContainer')

  public ngAfterViewInit(): void {
    new DotLottie({
      ...DEFAULT_CONFIG,
      ...this.animation(),
      canvas: this.animationContainer().nativeElement,
    })
  }
}
