import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { runOutsideAngular } from '@app/srv/di.service'
import { WriteFileResult } from '@capacitor/filesystem'
import { IonicModule } from '@ionic/angular'
import { IsoDate, localDate, StringMap } from '@naturalcycles/js-lib'
import { TrPipe } from '@naturalcycles/ngx-lib'
import { DataFlag, Goal, Mens, UFColor } from '@naturalcycles/shared'
import { EVENT } from '@src/app/analytics/analytics.cnst'
import { verticalSlideAnimation } from '@src/app/animations/vertical-slide'
import { AnimationComponent } from '@src/app/cmp/animation/animation.component'
import { ANIMATION } from '@src/app/cnst/animations.cnst'
import { SNACKBAR } from '@src/app/cnst/snackbars.cnst'
import { isNativeApp } from '@src/app/cnst/userDevice.cnst'
import { loader, LoaderType } from '@src/app/decorators/decorators'
import { LoaderDirective } from '@src/app/dir/loader/loader.directive'
import { UFEntry } from '@src/app/model/uf.model'
import { MarkdownPipe } from '@src/app/pipes/markdown.pipe'
import { StripTagsPipe } from '@src/app/pipes/strip-tags.pipe'
import { EventService } from '@src/app/srv/event.service'
import { MeasureStreakService } from '@src/app/srv/measureStreak.service'
import { SnackbarService } from '@src/app/srv/snackbar.service'
import { dispatch, getState } from '@src/app/srv/store.service'
import { UFService } from '@src/app/srv/uf.service'
import { NCShare } from '@src/typings/capacitor'
import { BehaviorSubject, Subscription } from 'rxjs'
import { SwiperOptions } from 'swiper'
import { SwiperComponent, SwiperModule } from 'swiper/angular'
import { slideOptions } from '../../cnst'
import { ICON, ICON_BY_DATAFLAG } from '../../cnst/icons.cnst'
import { ROUTES } from '../../cnst/nav.cnst'
import { BasePage } from '../base.page'

const MS = 10_000

@Component({
  selector: 'page-year-in-review',
  templateUrl: './year-in-review.page.html',
  styleUrls: ['./year-in-review.page.scss'],
  imports: [
    AnimationComponent,
    IonicModule,
    CommonModule,
    SwiperModule,
    TrPipe,
    MarkdownPipe,
    StripTagsPipe,
    LoaderDirective,
  ],
})
export class YearInReviewPage extends BasePage implements AfterViewInit, OnDestroy {
  override className = 'YearInReviewPage'
  protected override blockPopups = true

  private ufService = inject(UFService)
  private cdr = inject(ChangeDetectorRef)
  private measureStreakService = inject(MeasureStreakService)
  private analyticsService = inject(AnalyticsService)
  private snackbarService = inject(SnackbarService)
  private eventService = inject(EventService)

  protected override elementRef = inject(ElementRef)

  private timer?: NodeJS.Timeout
  private touchStartX = 0
  private touchStartY = 0
  private pauseTimeout?: NodeJS.Timeout
  private resumeSubscription?: Subscription

  protected name = getState().account.name1
  protected referralLink?: string

  // Slides config
  @ViewChild('slides')
  slides?: SwiperComponent

  protected config: SwiperOptions = {
    ...slideOptions,
    spaceBetween: 50,
    // initialSlide: 2,
  }

  protected visibleSlides: SlideConfig[] = []
  protected slidesVisibility: Record<string, boolean> = {}
  protected metricVisibility: Record<string, boolean> = {}
  protected currentSlide?: SlideConfig

  // Metrics
  protected goal?: Goal
  protected cycles = 0
  protected periodDays = 0
  protected measuredDays = 0
  protected longestStreak = 0
  protected greenDays = 0
  protected mood?: DataFlag

  protected measuredDaysShare = 0
  protected measuredDaysShareSegment?: string
  protected hasSufficientMeasuredDays?: boolean

  protected greenDaysShare = 0
  protected hasSufficientGreenDays?: boolean

  protected measuringStreakShare = 0
  protected measuringStreakShareSegment?: string
  protected hasSufficientMeasuringStreak?: boolean

  protected moodsShare = 0

  // Others
  protected Goal = Goal
  protected ROUTES = ROUTES
  protected ICON = ICON
  protected ICON_BY_DATAFLAG = ICON_BY_DATAFLAG
  protected DataFlag = DataFlag
  protected slideOptions: SwiperOptions = slideOptions
  protected incorrectClicked: number | undefined
  protected buttonState: 'correct' | 'incorrect' | undefined
  protected slidesWithConfetti = []
  protected stepProgress: StringMap<BehaviorSubject<number>> = {}
  protected paused = false
  protected entriesFromCurrentYear!: UFEntry[]
  protected personaDetails?: PersonaDetails

  protected orangePurpleAnimation = ANIMATION['orange-purple-orbs']
  protected purpleBlueAnimation = ANIMATION['purple-blue-orbs']
  protected transitionAnimation = ANIMATION['persona-transition']
  protected companyAnimation = ANIMATION['company-orbs']

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  public handleTouch(event: TouchEvent): void {
    const clickedButton = event
      .composedPath()
      .some(
        e =>
          (e as HTMLElement).nodeName === 'ION-BUTTON' ||
          (e as HTMLElement).nodeName === 'ION-ITEM',
      )

    if (clickedButton) return

    if (event.type === 'touchstart') {
      this.onTouchStart(event)
    } else {
      this.onTouchEnd(event)
    }
  }

  // handle pause on touching the screen
  private onTouchStart(e: TouchEvent): void {
    this.touchStartX = e.changedTouches[0]!.clientX
    this.touchStartY = e.changedTouches[0]!.clientY

    this.pauseTimeout = setTimeout(() => {
      this.paused = true
      this.pauseTimeout = undefined
    }, 200)
  }

  private onTouchEnd(e: TouchEvent): void {
    const touchEndX = e.changedTouches[0]!.clientX
    const touchEndY = e.changedTouches[0]!.clientY

    if (
      !this.paused &&
      touchEndY > 100 &&
      Math.abs(this.touchStartX - touchEndX) + Math.abs(this.touchStartY - touchEndY) < 20
    ) {
      const width = this.elementRef.nativeElement.clientWidth

      if (touchEndX < width / 2) {
        void this.slides?.swiperRef.slidePrev()
      } else {
        void this.slides?.swiperRef.slideNext()
      }
    }

    this.paused = false

    if (!this.pauseTimeout) return
    clearTimeout(this.pauseTimeout)
  }

  public ngAfterViewInit(): void {
    dispatch('extendUserSettings', { yearInReviewOpenDate: localDate.today() })
    this.referralLink = this.replaceReferralId(getState().friendReferral.link)

    const { account } = getState()
    this.goal = account.goal

    this.subscriptions.push(
      this.ufService.ufEntries$.subscribe(async entries => {
        this.entriesFromCurrentYear = entries.filter(
          entry =>
            !entry.prediction &&
            localDate(entry.date).isBetween('2024-01-01' as IsoDate, '2024-12-31' as IsoDate, '[]'),
        )

        await this.collectData(this.entriesFromCurrentYear)
      }),
    )

    this.personaDetails = this.getPersona()

    if (!this.slides) return

    this.slides.swiperRef.on('slideChangeTransitionStart', async () => {
      await this.onSlideWillChange()
    })

    this.slides.swiperRef.on('slideChangeTransitionEnd', async () => {
      await this.onSlideDidChange()
    })

    this.resumeSubscription = this.eventService.onResume$.subscribe(() => (this.paused &&= false))
  }

  public override ionViewDidEnter(): void {
    this.slidesWithConfetti = this.elementRef.nativeElement.querySelectorAll(
      ':not(.slide__share) > .confetti-slide',
    )
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy()
    this.resumeSubscription?.unsubscribe()
  }

  protected next(): void {
    if (!this.slides) return
    void this.slides.swiperRef.slideNext()
  }

  protected close(): void {
    void this.navigateBack(
      ROUTES.InsightsPage,
      {
        analytics: { source: 'YearInReview' },
      },
      verticalSlideAnimation,
    )
  }

  protected async onSlideWillChange(): Promise<void> {
    if (!this.slides) return
    this.slides?.swiperRef.update()

    const index = this.slides.swiperRef.activeIndex
    this.currentSlide = this.visibleSlides[index]

    this.cdr.detectChanges()
  }

  protected async onSlideDidChange(): Promise<void> {
    if (!this.slides) return

    const isBeginning = this.slides.swiperRef.isBeginning
    const isEnd = this.slides.swiperRef.isEnd
    const index = this.slides.swiperRef.activeIndex

    if (this.timer) clearInterval(this.timer)

    if (isBeginning || isEnd) return

    let count = 0

    this.timer = setInterval(() => {
      if (this.paused) return

      Object.entries(this.stepProgress).forEach(([key, value]) => {
        if (Number(key) < index) value?.next(1)
        if (Number(key) > index) value?.next(0)
      })

      count += 100

      this.stepProgress[index] ||= new BehaviorSubject(0)
      this.stepProgress[index]!.next(count / MS)

      if (count === MS) this.next()
      this.cdr.detectChanges()
    }, 100)

    this.analyticsService.trackEvent(EVENT.YIR_SLIDE_VIEW, {
      slide: this.currentSlide?.id,
      persona: this.currentSlide?.id === 'persona-reveal' ? this.personaDetails?.type : undefined,
    })

    this.removeQuizAnswerClasses()

    if (!this.currentSlide?.confettiId) return

    void import('@app/srv/confetti.service').then(({ confettiService }) => {
      runOutsideAngular(() => {
        void confettiService.addConfetti(
          this.slidesWithConfetti[this.currentSlide?.confettiId! - 1],
          {
            x: 0.5,
            y: 0.5,
          },
        )
      })
    })
  }

  private getTopSegmentForShare(share: number): string {
    if (share >= 0.9) return '5%'
    if (share >= 0.8) return '10%'
    if (share >= 0.7) return '15%'
    if (share >= 0.5) return '20%'

    return '30%'
  }

  private getPersona(): PersonaDetails {
    let persona = Persona.CYCLE_EXPLORER

    if (this.entriesFromCurrentYear.length < 30) {
      persona = Persona.CYCLE_EXPLORER
    } else if (
      this.entriesFromCurrentYear.length > 129
        ? this.measuringStreakShare >= 0.5
        : this.measuringStreakShare >= 0.7
    ) {
      persona = Persona.MEASURER
    } else if (this.measuredDaysShare >= 0.7) {
      persona = Persona.HABIT_HERO
    } else if (this.moodsShare >= 0.3) {
      persona = Persona.SELF_EMPATH
    } else if (this.greenDaysShare >= 0.35 && this.goal !== Goal.PLAN) {
      persona = Persona.GREEN_GODDESS
    } else if (this.entriesFromCurrentYear.length > 100) {
      persona = Persona.HABIT_HERO
    }

    return {
      type: persona,
      title: `year-in-review-persona-title--${persona}`,
      body: `year-in-review-persona-body--${persona}`,
      image: `https://assets.naturalcycles.com/images/year-in-review/${persona.toLowerCase()}.gif`,
      sparkles: `https://assets.naturalcycles.com/images/year-in-review/sparkles_${persona.toLowerCase()}.png`,
    }
  }

  private async collectData(entries: UFEntry[]): Promise<void> {
    const planOrPrevent = this.goal === Goal.PREVENT || this.goal === Goal.PLAN
    const currentSlideIndex = this.slides?.swiperRef.activeIndex ?? 0

    this.cycles = entries.filter(e => e.cd === 1).length - 1

    this.periodDays = entries.filter(e => e.mens === Mens.MENSTRUATION).length

    this.measuredDays = entries.filter(e => e.temperature).length
    this.measuredDaysShare = this.measuredDays / entries.length
    this.hasSufficientMeasuredDays = this.measuredDaysShare > 0.3
    this.measuredDaysShareSegment = this.getTopSegmentForShare(this.measuredDaysShare)

    const streaks = this.measureStreakService.getMeasureStreaks(entries)
    this.longestStreak = this.measureStreakService.getLongestMeasureStreak(streaks)
    this.measuringStreakShare = this.longestStreak / entries.length
    this.hasSufficientMeasuringStreak = this.measuringStreakShare > 0.3
    this.measuringStreakShareSegment = this.getTopSegmentForShare(this.measuringStreakShare)

    this.greenDays = entries.filter(e => e.color === UFColor.GREEN).length
    this.greenDaysShare = this.greenDays / entries.length
    this.hasSufficientGreenDays = this.greenDaysShare > 0.35

    const moodEntries = entries.filter(e => e.moods?.length).flatMap(e => e.moods)
    this.mood = moodEntries
      .sort(
        (a, b) => moodEntries.filter(v => v === a).length - moodEntries.filter(v => v === b).length,
      )
      .pop()
    this.moodsShare = moodEntries.length / entries.length

    this.metricVisibility = {
      cycles: this.cycles >= 2,
      periodDays: this.periodDays >= 2 && this.goal === Goal.PREVENT,
      measuredDays: this.measuredDays >= 2 && planOrPrevent,
      longestStreak: this.longestStreak > 5 && planOrPrevent,
      greenDays: this.greenDays > 0 && this.goal === Goal.PREVENT,
    }

    const slidesConfig: SlideConfig[] = [
      { id: 'intro', visible: true, hideProgress: true, hideShare: true },
      { id: 'personal-cycles', visible: this.metricVisibility['cycles']! },
      { id: 'personal-periodDays', visible: this.metricVisibility['periodDays']! },
      {
        id: 'personal-measuredDays',
        visible: this.metricVisibility['measuredDays']!,
        confettiId: this.measuredDaysShare >= 0.8 ? 1 : undefined,
      },
      {
        id: 'personal-longestStreak',
        visible: this.metricVisibility['longestStreak']!,
        confettiId: this.measuringStreakShare >= 0.8 ? 2 : undefined,
      },
      { id: 'personal-greenDays', visible: this.metricVisibility['greenDays']! },
      { id: 'personal-mood', visible: true },
      { id: 'transition-1', visible: true },
      { id: 'persona-reveal', visible: true, reverseToolbar: true },
      { id: 'community-transition', visible: true, reverseToolbar: true },
      { id: 'community-measure-quiz', visible: true, reverseToolbar: true },
      { id: 'community-measure-answer', visible: true, reverseToolbar: true, confettiId: 3 },
      { id: 'community-sex-quiz', visible: true, reverseToolbar: true },
      { id: 'community-sex-answer', visible: true, reverseToolbar: true },
      { id: 'community-mood-quiz', visible: true, reverseToolbar: true },
      { id: 'community-mood-answer', visible: true, reverseToolbar: true },
      { id: 'company-science', visible: true, reverseToolbar: true },
      { id: 'company-postpartum', visible: true, reverseToolbar: true },
      { id: 'company-insights', visible: true, reverseToolbar: true },
      { id: 'raf', visible: true, hideShare: true },
      {
        id: 'summary',
        visible: true,
        hideShare: true,
      },
      { id: 'outro', visible: true, hideProgress: true, hideShare: true },
    ]

    this.visibleSlides = slidesConfig.filter(slide => slide.visible)
    this.slidesVisibility = Object.fromEntries(slidesConfig.map(slide => [slide.id, slide.visible]))
    this.currentSlide = this.visibleSlides[currentSlideIndex]

    this.slides?.swiperRef.update()
  }

  protected handleButtonClick(
    event: Event,
    buttonState: 'correct' | 'incorrect',
    incorrectId?: number,
  ): void {
    this.removeQuizAnswerClasses()

    this.incorrectClicked = incorrectId ?? undefined
    this.buttonState = buttonState

    const clickedButton = event.currentTarget as HTMLElement
    clickedButton.classList.add(buttonState)

    const question = clickedButton.parentElement?.id

    this.navigateToNextSlideWithDelay()

    this.analyticsService.trackEvent(EVENT.YIR_QUIZ_ANSWER, {
      answer: clickedButton.id,
      isCorrect: buttonState,
      question: question!,
    })
  }

  private removeQuizAnswerClasses(): void {
    this.incorrectClicked = undefined
    this.buttonState = undefined

    const correctButtons = document.querySelectorAll('.correct')
    correctButtons.forEach(button => button.classList.remove('correct'))

    const incorrectButtons = document.querySelectorAll('.incorrect')
    incorrectButtons.forEach(button => button.classList.remove('incorrect'))
  }

  private navigateToNextSlideWithDelay(): void {
    setTimeout(() => {
      this.next()
    }, 500)
  }

  private copyToClipboard(): void {
    if (!this.referralLink) return
    void navigator.clipboard.writeText(this.referralLink).then(() => {
      void this.snackbarService.showSnackbar(SNACKBAR.REFER_FRIEND)
    })
  }

  public refer(): void {
    if (window.Capacitor.isNativePlatform()) {
      if (!this.referralLink) return
      void NCShare.share({ url: this.referralLink })
      return
    }

    this.copyToClipboard()
  }

  protected replaceReferralId(url?: string): string | undefined {
    return url?.replace('referralid20', 'referralid2420')
  }

  @loader(LoaderType.BUTTON)
  protected async share(): Promise<void> {
    if (!this.currentSlide) return

    this.paused = true

    const target = `#share-${this.currentSlide.id}`
    const element = document.querySelector(target)

    element?.classList.add('show')

    const { generatePng } = await import('@src/app/util/screenshot.util')

    const file = (await generatePng({
      elements: [
        {
          target,
          filename: 'Year in review 2024',
          backgroundColor: this.currentSlide.reverseToolbar ? '#fff' : '#560246',
        },
      ],
    })) as WriteFileResult

    element?.classList.remove('show')

    this.cdr.detectChanges()

    this.analyticsService.trackEvent(EVENT.YIR_SHARE, {
      slide: this.currentSlide.id,
    })

    if (file && isNativeApp) {
      await NCShare.share({ url: file.uri })
    }

    this.paused = false
  }
}

interface SlideConfig {
  id: string
  visible: boolean
  hideProgress?: boolean
  reverseToolbar?: boolean
  confettiId?: number
  background?: string
  hideShare?: boolean
}

enum Persona {
  MEASURER = 'MEASURER',
  HABIT_HERO = 'HABIT_HERO',
  SELF_EMPATH = 'SELF_EMPATH',
  GREEN_GODDESS = 'GREEN_GODDESS',
  CYCLE_EXPLORER = 'CYCLE_EXPLORER',
}

interface PersonaDetails {
  type: Persona
  title: string
  body: string
  image: string
  sparkles: string
}

export const YEAR_IN_REVIEW_START_DATE_PROD = '2024-12-18' as IsoDate
export const YEAR_IN_REVIEW_START_DATE_MASTER = '2024-11-25' as IsoDate
export const YEAR_IN_REVIEW_END_DATE = '2025-02-01' as IsoDate
export const YEAR_IN_REVIEW_LAST_START_DATE = '2023-12-15' as IsoDate
export const YEAR_IN_REVIEW_LAST_END_DATE = '2024-01-02' as IsoDate
