<div id="sharedContainer">
  <div
    class="animation__background"
    [class.animation__background--white]="currentSlide?.reverseToolbar"
    [class.animation__background--hide]="currentSlide?.id?.startsWith('community')"
  >
    <app-animation [animation]="orangePurpleAnimation" />
  </div>

  <div
    class="animation__background"
    [class.animation__background--hide]="currentSlide?.id !== 'transition-1'"
  >
    <app-animation [animation]="transitionAnimation" />
  </div>

  <div
    class="animation__background animation__background--image"
    [class.animation__background--hide]="currentSlide?.id !== 'persona-reveal'"
  ></div>

  <div
    class="animation__background animation__background--white"
    [class.animation__background--hide]="!currentSlide?.id?.startsWith('community')"
  >
    <app-animation [animation]="purpleBlueAnimation" />
  </div>

  <div
    class="animation__background animation__background--white"
    [class.animation__background--hide]="!currentSlide?.id?.startsWith('company')"
  >
    <app-animation [animation]="companyAnimation" />
  </div>

  <ion-header>
    <ion-toolbar mode="ios">
      <div slot="start" class="logo">
        <ion-icon
          appearance="LIGHT"
          [src]="ICON.NC_MONOGRAM"
          [color]="currentSlide?.reverseToolbar ? 'secondary' : 'white'"
        />

        @if (currentSlide?.id !== 'intro') {
          <ion-text [color]="currentSlide?.reverseToolbar ? 'black' : 'white'">| 2024</ion-text>
        }
      </div>
      <ion-buttons slot="end">
        <ion-back-button
          id="closeBtn"
          mode="md"
          icon="close"
          [defaultHref]="ROUTES.MessagesPage"
          [color]="currentSlide?.reverseToolbar ? 'black' : 'white'"
        />
      </ion-buttons>
    </ion-toolbar>

    <div class="pagination__container">
      <div class="pagination" [class.pagination--hidden]="currentSlide?.hideProgress || paused">
        @for (slide of visibleSlides; track $index) {
          @if (!slide.hideProgress) {
            <ion-progress-bar
              class="pagination__progress"
              [class.pagination__progress--reverse]="currentSlide?.reverseToolbar"
              [value]="stepProgress[$index] | async"
            />
          }
        }
      </div>
    </div>
  </ion-header>

  <swiper #slides [config]="config">
    @if (slidesVisibility['intro']) {
      <ng-template swiperSlide>
        <div class="slide intro">
          <div class="intro__img">
            <img src="/assets/img/year-in-review/year-in-review.svg" aria-hidden="true" alt="" />
          </div>

          <h1>{{ 'year-in-review-intro-title' | tmd: { name } | stripTags }}</h1>

          <p>{{ 'year-in-review-intro-body' | tr }}</p>

          <ion-button shape="round" (click)="next()">
            <ion-label>{{ 'txt-start' | tr }}</ion-label>
          </ion-button>
        </div>
      </ng-template>
    }

    <!-- personal stats -->
    @if (slidesVisibility['personal-cycles']) {
      <ng-template swiperSlide>
        <ng-template #personalCycles>
          <div class="slide slide__content slide__personal-1">
            <div class="slide__content__header">{{ 'year-in-review-personal-1-title-1' | tr }}</div>
            <div class="slide__content__number">{{ cycles }}</div>
            <div class="slide__content__header">{{ 'year-in-review-personal-1-title-2' | tr }}</div>
          </div>
        </ng-template>

        <ng-container *ngTemplateOutlet="personalCycles" />

        <div id="share-personal-cycles" class="slide__share">
          <ng-container *ngTemplateOutlet="shareLogo" />
          <ng-container *ngTemplateOutlet="personalCycles" />
        </div>
      </ng-template>
    }

    @if (slidesVisibility['personal-periodDays']) {
      <ng-template swiperSlide>
        <ng-template #personalPeriodDays>
          <div class="slide slide__content slide__personal-2">
            <div class="slide__content__header">{{ 'year-in-review-personal-2-title-1' | tr }}</div>
            <div class="slide__content__number">{{ periodDays }}</div>
            <div class="slide__content__header">{{ 'year-in-review-personal-2-title-2' | tr }}</div>
          </div>
        </ng-template>

        <ng-container *ngTemplateOutlet="personalPeriodDays" />

        <div id="share-personal-periodDays" class="slide__share">
          <ng-container *ngTemplateOutlet="shareLogo" />
          <ng-container *ngTemplateOutlet="personalPeriodDays" />
        </div>
      </ng-template>
    }

    @if (slidesVisibility['personal-measuredDays']) {
      <ng-template swiperSlide>
        <ng-template #personalMeasuredDays>
          <div class="slide slide__content slide__personal-3 confetti-slide">
            <div class="slide__content__header">{{ 'year-in-review-personal-3-title' | tr }}</div>
            <div class="slide__content__boxWithDays">
              <div class="slide__content__number">{{ measuredDays }}</div>
              <span class="slide__content__daysTxt">{{ 'txt-days' | tr }}</span>
            </div>

            @if (hasSufficientMeasuredDays) {
              <p
                [innerHTML]="
                  'year-in-review-personal-3-body-positive'
                    | tmd: { share: measuredDaysShareSegment }
                "
              ></p>
            } @else {
              <p>
                {{ 'year-in-review-personal-3-body-negative' | tr }}
              </p>
            }
          </div>
        </ng-template>

        <ng-container *ngTemplateOutlet="personalMeasuredDays" />

        <div id="share-personal-measuredDays" class="slide__share">
          <ng-container *ngTemplateOutlet="shareLogo" />
          <ng-container *ngTemplateOutlet="personalMeasuredDays" />
        </div>
      </ng-template>
    }

    @if (slidesVisibility['personal-longestStreak']) {
      <ng-template swiperSlide>
        <ng-template #personalLongestStreak>
          <div class="slide slide__content slide__personal-4 confetti-slide">
            <div class="slide__content__header">{{ 'year-in-review-personal-4-title-1' | tr }}</div>
            <div class="slide__content__header slide__content__header--small">
              {{ 'year-in-review-personal-4-title-2' | tr }}
            </div>
            <div class="slide__content__boxWithDays">
              <div class="slide__content__number">{{ longestStreak }}</div>
              <span class="slide__content__daysTxt">{{ 'txt-days' | tr }}</span>
            </div>

            @if (hasSufficientMeasuringStreak) {
              <p
                [innerHTML]="
                  'year-in-review-personal-4-body-positive'
                    | tmd: { share: measuringStreakShareSegment }
                "
              ></p>
            }
          </div>
        </ng-template>

        <ng-container *ngTemplateOutlet="personalLongestStreak" />

        <div id="share-personal-longestStreak" class="slide__share">
          <ng-container *ngTemplateOutlet="shareLogo" />
          <ng-container *ngTemplateOutlet="personalLongestStreak" />
        </div>
      </ng-template>
    }

    @if (slidesVisibility['personal-greenDays']) {
      <ng-template swiperSlide>
        <ng-template #personalGreenDays>
          <div class="slide slide__content slide__personal-5">
            <div class="slide__content__header">
              {{ 'year-in-review-personal-5-title-1' | tr }}
              <span class="slide__content__number">{{ greenDays }}</span>
              {{ 'year-in-review-personal-5-title-2' | tr }}
            </div>

            @if (hasSufficientGreenDays) {
              <p>
                {{ 'year-in-review-personal-5-body-positive' | tr }}
              </p>
            }
          </div>
        </ng-template>

        <ng-container *ngTemplateOutlet="personalGreenDays" />

        <div id="share-personal-greenDays" class="slide__share">
          <ng-container *ngTemplateOutlet="shareLogo" />
          <ng-container *ngTemplateOutlet="personalGreenDays" />
        </div>
      </ng-template>
    }

    @if (slidesVisibility['personal-mood']) {
      <ng-template swiperSlide>
        <ng-template #personalMood>
          <div class="slide slide__content slide__personal-6">
            <div class="slide__row">
              @if (mood) {
                <div
                  class="slide__content__header"
                  [innerHTML]="
                    'year-in-review-personal-6-title-positive'
                      | tmd: { mood: 'txt-dataflag--' + DataFlag[mood!] | tr }
                  "
                ></div>
              } @else {
                <div class="slide__content__header">
                  {{ 'year-in-review-personal-6-title-negative' | tr }}
                </div>
              }
            </div>

            <div class="slide__row slide__row--shrink">
              @if (mood) {
                <div class="slide__content__icon">
                  <ion-icon [src]="ICON_BY_DATAFLAG[mood]" />
                </div>
              } @else {
                <img
                  src="https://assets.naturalcycles.com/images/year-in-review/moods.png"
                  alt="No moods"
                />
              }
            </div>

            <div class="slide__row">
              <p>
                {{
                  (mood
                    ? 'year-in-review-personal-6-body-positive'
                    : 'year-in-review-personal-6-body-negative'
                  ) | tr
                }}
              </p>
            </div>
          </div>
        </ng-template>

        <ng-container *ngTemplateOutlet="personalMood" />

        <div id="share-personal-mood" class="slide__share">
          <ng-container *ngTemplateOutlet="shareLogo" />
          <ng-container *ngTemplateOutlet="personalMood" />
        </div>
      </ng-template>

      @if (slidesVisibility['transition-1']) {
        <ng-template swiperSlide>
          <ng-template #transition1>
            <div class="slide slide__content slide__transition-1">
              <p>{{ 'year-in-review-transition-1' | tr }}</p>
            </div>
          </ng-template>

          <ng-container *ngTemplateOutlet="transition1" />

          <div id="share-transition-1" class="slide__share">
            <ng-container *ngTemplateOutlet="shareLogo" />
            <ng-container *ngTemplateOutlet="transition1" />
          </div>
        </ng-template>
      }

      @if (slidesVisibility['persona-reveal'] && personaDetails) {
        <ng-template swiperSlide>
          <ng-template #personaReveal>
            <div class="slide slide__content slide__persona">
              <div class="slide__row slide__row--shrink slide__row--gif {{ personaDetails.type }}">
                <img id="gif" class="slide__persona__image" [src]="personaDetails.image" alt="" />
                <img class="sparkleImg" [src]="personaDetails.sparkles" alt="" />
              </div>

              <div class="slide__row">
                <span class="slide__persona__title">{{ personaDetails.title | tr }}</span>
                <p class="slide__persona__body">{{ personaDetails.body | tr }}</p>
              </div>
            </div>
          </ng-template>

          <ng-container *ngTemplateOutlet="personaReveal" />

          <div id="share-persona-reveal" class="slide__share slide__share--white">
            <ng-container *ngTemplateOutlet="shareLogo" />
            <ng-container *ngTemplateOutlet="personaReveal" />
          </div>
        </ng-template>
      }
    }

    <ng-template swiperSlide>
      <ng-template #communityTransition>
        <div class="slide slide__community">
          <div class="slide__community__title slide__community__title--transition">
            {{ 'year-in-review-community-transition' | tr }}
          </div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="communityTransition" />

      <div id="share-community-transition" class="slide__share slide__share--white">
        <ng-container *ngTemplateOutlet="shareLogo" />
        <ng-container *ngTemplateOutlet="communityTransition" />
      </div>
    </ng-template>

    <!-- community stats with quiz -->
    <ng-template swiperSlide>
      <ng-template #communityMeasureQuiz>
        <div class="slide slide__community">
          <div class="slide__community__title">
            {{ 'year-in-review-community-1-question' | tr }}
          </div>
          <div class="slide__community__body">{{ 'year-in-review-community-1-hint' | tr }}</div>
          <div class="slide__community__imageWrapper">
            <img
              class="hardware-image"
              src="https://assets.naturalcycles.com/images/year-in-review/hardwares.png"
              aria-label="hidden"
              alt=""
            />
          </div>
          <div id="HowManyMeasured" class="slide__community__buttons">
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'incorrect', 1)"
              id="64Million"
              appearance="LIGHT"
            >
              {{ 'year-in-review-community-1-option-1' | tr }}
              @if (incorrectClicked === 1) {
                <ion-icon [src]="ICON.CLOSE" color="danger" slot="end" />
              }
            </ion-item>
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'incorrect', 2)"
              id="108Million"
              appearance="LIGHT"
            >
              {{ 'year-in-review-community-1-option-2' | tr }}
              @if (incorrectClicked === 2) {
                <ion-icon [src]="ICON.CLOSE" color="danger" slot="end" />
              }
            </ion-item>
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'correct')"
              id="122Million"
              appearance="LIGHT"
            >
              {{ 'year-in-review-community-1-option-3' | tr }}
              @if (buttonState === 'correct') {
                <ion-icon [src]="ICON.CHECK_OUTLINE" color="success" slot="end" />
              }
            </ion-item>
          </div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="communityMeasureQuiz" />

      <div id="share-community-measure-quiz" class="slide__share slide__share--white">
        <ng-container *ngTemplateOutlet="shareLogo" />
        <ng-container *ngTemplateOutlet="communityMeasureQuiz" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <ng-template #communityMeasureAnswer>
        <div class="slide slide__community confetti-slide">
          <p class="slide__community__result__1__title">
            {{ 'year-in-review-community-1-answer-1' | tr }}
          </p>
          <span class="slide__community__result__1__number">122</span>

          <span
            class="slide__community__result__1__number slide__community__result__1__number--withoutTopMargin"
            >{{ 'txt-millions' | tr }}</span
          >

          <div class="slide__community__result__1__subtitle">
            {{ 'year-in-review-community-1-answer-3' | tr }}
          </div>
          <div class="slide__community__body">
            {{ 'year-in-review-community-1-answer-context' | tr }}
          </div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="communityMeasureAnswer" />

      <div id="share-community-measure-answer" class="slide__share slide__share--white">
        <ng-container *ngTemplateOutlet="shareLogo" />
        <ng-container *ngTemplateOutlet="communityMeasureAnswer" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <ng-template #communitySexQuiz>
        <div class="slide slide__community">
          <div class="slide__community__title">
            {{ 'year-in-review-community-2-question' | tr }}
          </div>
          <div class="slide__community__imageWrapper">
            <img
              class="calendar-image"
              src="https://assets.naturalcycles.com/images/year-in-review/calendar_illustration.png"
              aria-label="hidden"
              alt=""
            />
          </div>
          <div id="SexiestDay" class="slide__community__buttons">
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'incorrect', 1)"
              id="Friday"
              appearance="LIGHT"
            >
              {{ 'year-in-review-community-2-option-1' | tr }}
              @if (incorrectClicked === 1) {
                <ion-icon [src]="ICON.CLOSE" color="danger" slot="end" />
              }
            </ion-item>
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'incorrect', 2)"
              id="Saturday"
              appearance="LIGHT"
            >
              {{ 'txt-saturday' | tr }}
              @if (incorrectClicked === 2) {
                <ion-icon [src]="ICON.CLOSE" color="danger" slot="end" />
              }
            </ion-item>
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'correct')"
              id="Sunday"
              appearance="LIGHT"
            >
              {{ 'txt-sunday' | tr }}
              @if (buttonState === 'correct') {
                <ion-icon [src]="ICON.CHECK_OUTLINE" color="success" slot="end" />
              }
            </ion-item>
          </div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="communitySexQuiz" />

      <div id="share-community-sex-quiz" class="slide__share slide__share--white">
        <ng-container *ngTemplateOutlet="shareLogo" />
        <ng-container *ngTemplateOutlet="communitySexQuiz" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <ng-template #communitySexAnswer>
        <div class="slide slide__community">
          <p class="slide__community__result__2__title">{{ 'txt-sunday' | tr }}</p>
          <div class="slide__community__title">{{ 'year-in-review-community-2-answer' | tr }}</div>
          <div class="slide__community__result__2__context">
            {{ 'year-in-review-community-2-answer-context' | tr }}
          </div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="communitySexAnswer" />

      <div id="share-community-sex-answer" class="slide__share slide__share--white">
        <ng-container *ngTemplateOutlet="shareLogo" />
        <ng-container *ngTemplateOutlet="communitySexAnswer" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <ng-template #communityMoodQuiz>
        <div class="slide slide__community">
          <div class="slide__community__title">
            {{ 'year-in-review-community-3-question' | tr }}
          </div>
          <div id="MostLoggedMood" class="slide__community__buttons">
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'incorrect', 1)"
              id="Calm"
              appearance="LIGHT"
            >
              <ion-icon [src]="ICON.MOOD_CALM" slot="start" />
              {{ 'txt-dataflag--MOOD_CALM' | tr }}
              @if (incorrectClicked === 1) {
                <ion-icon [src]="ICON.CLOSE" color="danger" slot="end" />
              }
            </ion-item>
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'incorrect', 2)"
              id="SelfCritical"
              appearance="LIGHT"
            >
              <ion-icon [src]="ICON.MOOD_SELF_CRITICAL" slot="start" />
              {{ 'txt-dataflag--MOOD_SELF_CRITICAL' | tr }}
              @if (incorrectClicked === 2) {
                <ion-icon [src]="ICON.CLOSE" color="danger" slot="end" />
              }
            </ion-item>
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'correct')"
              id="Tired"
              appearance="LIGHT"
            >
              <ion-icon [src]="ICON.MOOD_TIRED" slot="start" />
              {{ 'txt-dataflag--MOOD_TIRED' | tr }}
              @if (buttonState === 'correct') {
                <ion-icon [src]="ICON.CHECK_OUTLINE" color="success" slot="end" />
              }
            </ion-item>
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'incorrect', 3)"
              id="Confident"
              appearance="LIGHT"
            >
              <ion-icon [src]="ICON.MOOD_CONFIDENT" slot="start" />
              {{ 'txt-dataflag--MOOD_CONFIDENT' | tr }}
              @if (incorrectClicked === 3) {
                <ion-icon [src]="ICON.CLOSE" color="danger" slot="end" />
              }
            </ion-item>
            <ion-item
              button
              class="slide__community__button"
              (click)="handleButtonClick($event, 'incorrect', 4)"
              id="Irritable"
              appearance="LIGHT"
            >
              <ion-icon [src]="ICON.MOOD_IRRITABLE" slot="start" />
              {{ 'txt-dataflag--MOOD_IRRITABLE' | tr }}
              @if (incorrectClicked === 4) {
                <ion-icon [src]="ICON.CLOSE" color="danger" slot="end" />
              }
            </ion-item>
          </div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="communityMoodQuiz" />

      <div id="share-community-mood-quiz" class="slide__share slide__share--white">
        <ng-container *ngTemplateOutlet="shareLogo" />
        <ng-container *ngTemplateOutlet="communityMoodQuiz" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <ng-template #communityMoodAnswer>
        <div class="slide slide__community">
          <div class="slide__community__title">
            {{ 'year-in-review-community-3-answer' | tr }}
          </div>
          <div class="slide__community__result__3__wrapper">
            <div class="slide__community__result__3__wrapper__row">
              <div
                class="slide__community__result__3__wrapper__row__bar slide__community__result__3__wrapper__row__bar--1"
                [style.animation-play-state]="
                  currentSlide?.id === 'community-mood-answer' ? 'running' : undefined
                "
              >
                <ion-icon [src]="ICON.MOOD_TIRED" />
                <span>{{ 'txt-dataflag--MOOD_TIRED' | tr }}</span>
              </div>
            </div>
            <div class="slide__community__result__3__wrapper__row">
              <div
                class="slide__community__result__3__wrapper__row__bar slide__community__result__3__wrapper__row__bar--2"
                [style.animation-play-state]="
                  currentSlide?.id === 'community-mood-answer' ? 'running' : undefined
                "
              >
                <ion-icon [src]="ICON.MOOD_IRRITABLE" />
                <span>{{ 'txt-dataflag--MOOD_IRRITABLE' | tr }}</span>
              </div>
            </div>
            <div class="slide__community__result__3__wrapper__row">
              <div
                class="slide__community__result__3__wrapper__row__bar slide__community__result__3__wrapper__row__bar--3"
                [style.animation-play-state]="
                  currentSlide?.id === 'community-mood-answer' ? 'running' : undefined
                "
              >
                <ion-icon [src]="ICON.MOOD_CALM" />
                <span>{{ 'txt-dataflag--MOOD_CALM' | tr }}</span>
              </div>
            </div>
          </div>
          <div class="slide__community__result__3__context">
            {{ 'year-in-review-community-3-answer-context' | tr }}
          </div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="communityMoodAnswer" />

      <div id="share-community-mood-answer" class="slide__share slide__share--white">
        <ng-container *ngTemplateOutlet="shareLogo" />
        <ng-container *ngTemplateOutlet="communityMoodAnswer" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <ng-template #companyScience>
        <div class="slide slide__company">
          <div class="slide__row">
            <div class="slide__company__1__content">
              <div class="slide__company__title">
                {{ 'year-in-review-company-1-title-1' | tr }}
              </div>
              <div class="slide__company__1__content__number">4</div>
              <div class="slide__company__title__center">
                <div class="slide__company__title slide__company__title--small">
                  {{ 'year-in-review-company-1-title-2' | tr }}
                </div>
              </div>
            </div>
            <div class="slide__company__1__subtitle">
              {{ 'year-in-review-company-1-subtitle' | tr }}
            </div>
          </div>

          <div class="slide__row slide__row--shrink">
            <img
              class="slide__company__1__img"
              src="https://assets.naturalcycles.com/images/year-in-review/cards.png"
              aria-label="hidden"
              alt=""
            />
          </div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="companyScience" />

      <div id="share-company-science" class="slide__share slide__share--white">
        <ng-container *ngTemplateOutlet="shareLogo" />
        <ng-container *ngTemplateOutlet="companyScience" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <ng-template #companyPostpartum>
        <div class="slide slide__company slide__company__2">
          <div class="slide__row slide__company__2__content">
            <div class="slide__company__title slide__company__title--medium">
              {{ 'year-in-review-company-2-title' | tr }}
            </div>
            <div class="slide__company__2__subtitle">
              {{ 'year-in-review-company-2-subtitle' | tr }}
            </div>
          </div>
          <div class="slide__row slide__row--shrink slide__row--gif">
            <img
              class="slide__company__2__gif"
              src="https://assets.naturalcycles.com/images/year-in-review/is_mommy_ok.gif"
              aria-label="hidden"
              alt=""
            />
            <img
              class="slide__company__2__text"
              src="https://assets.naturalcycles.com/images/year-in-review/is_mommy_ok_text.png"
              aria-label="hidden"
              alt=""
            />
          </div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="companyPostpartum" />

      <div id="share-company-postpartum" class="slide__share slide__share--white">
        <ng-container *ngTemplateOutlet="shareLogo" />
        <ng-container *ngTemplateOutlet="companyPostpartum" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <ng-template #companyInsights>
        <div class="slide slide__company slide__company__3">
          <div class="slide__row slide__company__title">
            {{ 'year-in-review-company-3-title' | tr }}
          </div>

          <div class="slide__row slide__row--shrink">
            <img
              src="https://assets.naturalcycles.com/images/year-in-review/cycle_insights.png"
              aria-label="hidden"
              alt=""
            />
          </div>

          <div class="slide__row slide__company__3__subtitle">
            {{ 'year-in-review-company-3-subtitle' | tr }}
          </div>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="companyInsights" />

      <div id="share-company-insights" class="slide__share slide__share--white">
        <ng-container *ngTemplateOutlet="shareLogo" />
        <ng-container *ngTemplateOutlet="companyInsights" />
      </div>
    </ng-template>

    @if (slidesVisibility['raf']) {
      <ng-template swiperSlide>
        <div class="slide slide__content slide__raf">
          <div class="slide__row slide__row--shrink">
            <img src="/assets/img/year-in-review/refer-a-friend.svg" aria-hidden="true" alt="" />
          </div>

          <div class="slide__row raf__content">
            <h1>{{ 'year-in-review-raf-title' | tr }}</h1>
            <p>{{ 'year-in-review-raf-body' | tr }}</p>

            <ion-buttons appearance="LIGHT">
              <ion-button
                color="primary"
                shape="round"
                expand="full"
                fill="solid"
                uid="yearInReview__raf__shareBtn"
                (click)="refer()"
                >{{ 'year-in-review-raf-btn' | tr }}</ion-button
              >
              <ion-button
                color="primary"
                shape="round"
                fill="outline"
                expand="full"
                uid="yearInReview__raf__seeRoundupBtn"
                (click)="next()"
                >{{ 'year-in-review-see-roundup-btn' | tr }}</ion-button
              >
            </ion-buttons>
          </div>
        </div>
      </ng-template>
    }

    @if (slidesVisibility['summary']) {
      <ng-template swiperSlide>
        <ng-template #summary>
          <div class="slide summary">
            <!-- Empty element just to be able to space-between -->
            <div></div>
            <div class="background"></div>

            <div class="summary__container">
              <h1>{{ 'year-in-review-my-roundup' | tr }}</h1>
              <div class="summary__stats">
                @if (metricVisibility['cycles']) {
                  <div class="summary__stats__row">
                    <span>{{ cycles }}</span> <span>{{ 'cycles' | tr }}</span>
                  </div>
                }

                @if (metricVisibility['periodDays']) {
                  <div class="summary__stats__row">
                    <span>{{ periodDays }}</span>
                    <span>{{ 'year-in-review-personal-2-title-2' | tr }}</span>
                  </div>
                }

                @if (metricVisibility['measuredDays']) {
                  <div class="summary__stats__row">
                    <span>{{ measuredDays }}</span>
                    <span>{{ 'year-in-review-summary-days-measured' | tr }}</span>
                  </div>
                }

                @if (metricVisibility['longestStreak']) {
                  <div class="summary__stats__row">
                    <span>{{ longestStreak }}</span>
                    <span>{{ 'year-in-review-summary-longest-streak' | tr }}</span>
                  </div>
                }

                @if (metricVisibility['greenDays']) {
                  <div class="summary__stats__row">
                    <span>{{ greenDays }}</span>
                    <span>{{ 'year-in-review-personal-5-title-2' | tr }}</span>
                  </div>
                }
              </div>
            </div>

            <div class="summary__buttons">
              <!-- @if (isNativeApp) { -->
              <ion-button
                shape="round"
                expand="block"
                (click)="share()"
                class="summary__buttons__share"
                uid="yearInReview__summary__shareBtn"
                loader
              >
                <ion-label>{{ 'txt-share' | tr }}</ion-label>
                <ion-icon [src]="ICON.SHARE" size="small" />
              </ion-button>
              <!-- } -->

              <ion-button
                shape="round"
                expand="block"
                fill="outline"
                color="white"
                (click)="next()"
                uid="yearInReview__summary__finish__btn"
              >
                <ion-label>{{ 'txt-next' | tr }}</ion-label>
              </ion-button>
            </div>
          </div>
        </ng-template>

        <ng-container *ngTemplateOutlet="summary" />

        <div id="share-summary" class="slide__share slide__share">
          <ng-container *ngTemplateOutlet="shareLogo" />
          <ng-container *ngTemplateOutlet="summary" />
        </div>
      </ng-template>
    }

    <ng-template swiperSlide>
      <div id="Outro" class="slide slide__outro">
        <div class="background"></div>

        <h1 [innerHTML]="'year-in-review-outro-title' | tmd: { name }"></h1>

        <p>{{ 'year-in-review-outro-body' | tr }}</p>

        <ion-button shape="round" (click)="close()" uid="yearInReview__outro__btn">
          <ion-label>{{ 'year-in-review-see-data-btn' | tr }}</ion-label>
        </ion-button>
      </div>
    </ng-template>
  </swiper>

  @if (!currentSlide?.hideShare) {
    <ion-footer class="footer">
      <ion-button
        shape="round"
        [color]="currentSlide?.reverseToolbar ? 'secondary' : 'skin'"
        [ngClass]="currentSlide?.id === 'personal-cycles' ? 'footer--withText' : ''"
        (click)="share()"
        loader
      >
        <ion-label>
          @if (currentSlide?.id === 'personal-cycles') {
            {{ 'txt-share' | tr }}
          }
          <ion-icon
            [src]="ICON.SHARE"
            [color]="currentSlide?.reverseToolbar ? 'white' : 'secondary'"
            [style.margin-left]="currentSlide?.id === 'personal-cycles' ? '4px' : 0"
          />
        </ion-label>
      </ion-button>
    </ion-footer>
  }
</div>

<ng-template #shareLogo>
  <div class="slide__share__header logo">
    <ion-icon
      appearance="LIGHT"
      [src]="ICON.NC_MONOGRAM"
      [color]="currentSlide?.reverseToolbar ? 'secondary' : 'white'"
    />

    <ion-text [color]="currentSlide?.reverseToolbar ? 'black' : 'white'">| 2024</ion-text>
  </div>
</ng-template>
