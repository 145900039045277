import {
  BreakpointAction,
  ChoiceFunction,
  GoalBreakpointOption,
  QuizDataInput,
} from '@naturalcycles/shared'
import { Section } from '@src/app/cnst/quiz.cnst'
import { clearFlowAfterCurrentSection } from '@src/app/util/quiz.util'
import { QuizPage } from '../quiz-pages.cnst'

const commonTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  flow[Section.goal] = [
    QuizPage.fertilityGoal,
    QuizPage.fertilityGoalInfo,
    QuizPage.birthControlMethod,
    QuizPage.birthControlMethodInfo,
  ]
  return flow
}

const planTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  flow[Section.goal] = [
    QuizPage.fertilityGoal,
    QuizPage.fertilityGoalInfo,
    QuizPage.plannedPregnancyTiming,
  ]
  return flow
}

const shortQuizTransformFunction: ChoiceFunction<QuizPage, QuizDataInput> = flow => {
  flow[Section.goal] = [QuizPage.fertilityGoal]
  return clearFlowAfterCurrentSection(flow, Section.goal)
}

export const goalBreakpoint: Required<
  BreakpointAction<GoalBreakpointOption, QuizPage, QuizDataInput>
> = {
  [GoalBreakpointOption.prevent]: commonTransformFunction,
  [GoalBreakpointOption.preventNowPlanLater]: commonTransformFunction,
  [GoalBreakpointOption.plan]: planTransformFunction,
  [GoalBreakpointOption.undecided]: commonTransformFunction,
  [GoalBreakpointOption.trackPregnancy]: shortQuizTransformFunction,
  [GoalBreakpointOption.trackCycle]: commonTransformFunction,
  [GoalBreakpointOption.postPartum]: shortQuizTransformFunction,
  [GoalBreakpointOption.improveWellbeing]: commonTransformFunction,
  [GoalBreakpointOption.manageSymptoms]: commonTransformFunction,
}
