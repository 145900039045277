import { LoginProvider } from '@naturalcycles/shared'

export enum AuthPageMode {
  Signup = 'Signup',
  Login = 'Login',
  AnonymousModeEnter = 'AnonymousModeEnter',
  AnonymousModeExit = 'AnonymousModeExit',
}

export enum AuthProviderType {
  none = 'none',
  email = 'email',
  social = 'social',
}
export interface PrivacyTermsConsent {
  accepted: boolean
  marketingConsent: boolean
  marketingSocialConsent: boolean
  marketingSocialConsentTo: string | undefined
  marketingSocialConsentKey: string | undefined
  marketingSocialConsentFromATT: boolean
  consentTermsLanguage: string
}

export interface AuthInput {
  /**
   * Used to determine if we should run post registration side effects
   */
  accountAlreadyExists?: boolean
  email?: string
}

export interface EmailAuthProviderInput extends AuthInput {
  type: AuthProviderType.email
  email: string
  pw: string
}
export interface SocialAuthProviderInput extends AuthInput {
  type: AuthProviderType.social
  token: string
  id: string
  name?: string
  lastName?: string
  allowToCreateNewAccount: boolean
  accountAlreadyExists?: boolean
  pw?: string
  loginProvider: LoginProvider
}

export type AuthProviderResult = SocialAuthProviderResult | EmailAuthProviderResult

export interface SocialAuthProviderResult {
  type: AuthProviderType.social
  input: SocialAuthProviderInput
  mode: AuthPageMode
  consent?: PrivacyTermsConsent
}

export interface EmailAuthProviderResult {
  type: AuthProviderType.email
  input: EmailAuthProviderInput
  mode: AuthPageMode
  consent?: PrivacyTermsConsent
}
