// Prod is a base environment, all other environments extend it

import { MixPanelServiceConf } from '@app/analytics/analytics.model'

export class EnvironmentProduction {
  name = 'prod'
  prod = true
  dev = false
  test = false
  analyticsForAdmins = false
  mockBluetooth = false

  appId = 'com.naturalcycles.cordova'
  appName = 'Natural Cycles'
  capacitorLoggingBehavior: 'none' | 'debug' = 'none'
  backgroundRunnerWristTempEvent = 'hkBackgroundDeliveryProd'

  apiHost = 'https://api-prod.naturalcycles.com'
  apiUrl = this.apiHost + '/api/v3'

  loginHost = 'https://admin3.naturalcycles.com'

  websiteHost = 'https://www.naturalcycles.com'

  sentryDsn: string | undefined =
    'https://b92bf3ba86b2401b8c3f70ab83c7f100@o94439.ingest.sentry.io/5385981'

  alertOnJSError = false
  alertOnStart = false

  gmapsKey = 'AIzaSyCka29zJV5LLGpebXWM1-wqjLJDI1QK7SQ'

  mixpanelEnabled = true
  mixpanelServiceConf: MixPanelServiceConf = {
    mixpanelId: '1430318304171440bfd5c9b90be6ab60',
    prefixPageView: '',
    prefixEventCat: '',
    prefixEventAction: '',
    viewType: 'App', // is set in AnalyticsService, but defaults to App
  }

  adjustApiToken: string | undefined = '221z64qwitc0'
  adjustProd = true

  moeAppId: string | undefined = 'WY18Y648Q5E71WL1GIUDPLVM'

  firebasePerfEnabled = true

  firebaseCfg: any | undefined = {
    apiKey: 'AIzaSyD89KM65aSBOImz9xhZfU_lBceJ-5KwacI',
    authDomain: 'natural-cycles.firebaseapp.com',
    databaseURL: 'https://natural-cycles.firebaseio.com',
    projectId: 'natural-cycles',
    storageBucket: 'natural-cycles.appspot.com',
    messagingSenderId: '712514701016',
    appId: '1:712514701016:web:fcd8a014e9164d4c',
    measurementId: 'G-9NT6DRRD4K',
  }

  appleWebClientId = 'com.naturalcycles.websignin'
  appleWebRedirectURL = this.apiUrl + '/sessions/appleAuthCallback'
  appleBundleIdentifier = 'com.naturalcycles.cordova'

  googleAndroidClientId = '712514701016-ngn0r4guu82rcuufjk1bl82qr9qhv4rr.apps.googleusercontent.com'
  googleWebClientId = '496314433486-2vitum0n58a7aqmtfjrvp5le5up785mq.apps.googleusercontent.com'

  /**
   * Recaptcha should be enabled by default in production
   * The recaptchaSiteKey is unique per environment
   */
  recaptchaEnabled = true
  recaptchaSiteKey = '6LfgwJInAAAAAHrb6_dRNnO5tn0BpKdcTLRRv4jf'
}

export class Environment extends EnvironmentProduction {}

export const env = new EnvironmentProduction()
